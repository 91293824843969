import axios from 'utils/axios';
import { getBearerToken } from '../../utils/helpers/auth';

export interface ICodPricingItem {
    _id?: string;
    country: string;
    newLeadPrice: number;
    confirmedLeadPrice: number;
    deliveredLeadPrice: number;
    shippedLeadPrice: number;
    percentFromSellPrice: number;
    deliveredPriceforCC: number;
    upDownCross: number;
    declarationValue?: number;
    vat?: number;
    handlingPerUnit?: number;
}
export interface ICodNoCCPricing {
    _id?: string;
    country: string;
    deliveredLeadPrice: number;
    shippedLeadPrice: number;
    percentFromSellPrice: number;
    handleFees: number;
    declarationValue?: number;
    vat?: number;
    handlingPerUnit?: number;
}
export interface IPrepaidNoCCPricing {
    _id?: string;
    country: string;
    shippedLeadPrice: number;
    handleFees: number;
}

export interface IPricingLogistioBulkFees {
    codWithCC: Array<ICodPricingItem>;
    codNoCC: Array<ICodNoCCPricing>;
    prepaidNoCC: Array<IPrepaidNoCCPricing>;
}

export interface ILogistioBulkFees {
    _id: string;
    pricing: IPricingLogistioBulkFees;
    name: string;
    isDefault?: boolean;
    customersCount?: number;
    updatedAt?: string;
}

export const getLogistioBulkFeesList = async () => {
    const config = getBearerToken();
    const response = await axios.get<ILogistioBulkFees[]>(`/admin/logistioBulkFees`, config);
    return response;
};

export const deleteLogistioBulkFees = async (id: string) => {
    const config = getBearerToken();
    const response = await axios.delete(`/admin/logistioBulkFees/${id}`, config);
    return response;
};

export const updateLogistioBulkFees = async (id: string, data: any) => {
    const config = getBearerToken();
    const response = await axios.put(`/admin/logistioBulkFees/${id}`, data, config);
    return response;
};

export const createLogistioBulkFees = async (data: any) => {
    const config = getBearerToken();
    const response = await axios.post(`/admin/logistioBulkFees`, data, config);
    return response;
};
