/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
import Package from 'assets/package.png';
import Package2 from 'assets/no-image.png';
import { tableRowProps } from 'components/tables/tables.types';
import { Avatar, Checkbox } from 'flowbite-react';
import moment from 'moment';
import CAOrderActions from 'pages/CallAgentsPages/orders/CAOrderActions';
import CallAgentByCallAdminActions from 'pages/CallCenterAdmin/callAgentsList/callAgentTableActions/callAgentbyCallAdminActions';
import CCAOrderActions from 'pages/CallCenterAdmin/orders/CCAOrderActions';
import AccountManagerActions from 'pages/accountManagerList/accountManagerTableActions/accountManagerActions';
import AdminActions from 'pages/adminsList/adminTableActions/adminActions';
import CallAgentActions from 'pages/callAgentList/callAgentTableActions/callAgentActions';
import CallCenterAdminActions from 'pages/callCenterAdminList/callCenterAdminTableActions/callCenterAdminActions';
import CallCenterActions from 'pages/callCenterList/callCenterTableActions/callCenterActions';
import { ICustomer } from 'pages/customers/customer.types';
import CustomerActions from 'pages/customers/customerTableActions/customerActions';
import InvoiceActions from 'pages/invoices/invoice/invoiceActions';
import OrderActions from 'pages/orders/OrdersActions';
import PaymentActions from 'pages/payments/paymentsTableActions/paymentActions';
import ProductActions from 'pages/productList/productActions';
import StatusActions from 'pages/productList/statusActions';
import SourcingAgentActions from 'pages/productProviderList/sourcingAgentTableActions/sourcingAgentActions';
import { IPurchases } from 'pages/purchases/purchases.types';
import PurchasesActions from 'pages/purchases/purshasesActions';
import ResellerStockActions from 'pages/reseller/stocks/helpers/stockActions';
import WalletOperationActions from 'pages/walletOperationsList/operationsTableActions/walletOpActions';
import React from 'react';
import { HiMinus } from 'react-icons/hi';
import { IOrder } from 'services/admin/order';
import { IResellerStock } from 'services/admin/resellerStock';
import { currencyConverter } from 'utils/currencyConverter';
import PrepaidInvoiceActions from 'pages/invoices/prepaidInvoice/invoiceActions';
import ResellerProductActions from 'pages/reseller/products/resellerProductActions';
import CreditActions from 'pages/CreditsManagement/creditActions';
import ResellerProductContentActions from 'pages/reseller/products/ProductContentActions';
import CustomerResellerProductActions from 'pages/reseller/products/customerActions';
import OrdersResellerStockActions from 'pages/reseller/stocks/orderActions';
import PartnerFeesActions from 'pages/configurationAdmin/logistioBulkFees/partnerFees/partnerFeesActions';
import CampaignActions from 'pages/callCenterCampaignList/campaignActions';
import { ICampaign } from 'pages/callCenterCampaignList/campaign.types';

const TableRow: React.FC<tableRowProps> = ({
    el,
    index,
    selectable,
    // checkedBox,
    setSelectedRows,
    columns,
    icons,
    labels,
    setRows,
    currentPage,
    currentFilter,
    itemsSelected,
    setActiveTab,
    setStats,
}) => {
    return (
        <tr
            key={`${el._id}${index}`}
            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
            {selectable && (
                <th scope="col" className="p-4" key={`selectable${el._id}${index}`}>
                    <div className="flex items-center gap-2">
                        <Checkbox
                            value={el._id}
                            checked={itemsSelected[el._id] !== undefined || false}
                            onChange={(e) => {
                                setSelectedRows(el, e.target?.checked, index);
                            }}
                        />
                    </div>
                </th>
            )}
            {columns?.map((header) => {
                if (header.field === 'Action') {
                    switch (header.actionType) {
                        case 'render':
                            return header.renderCell ? <td className="px-4 py-4">{header.renderCell(el)}</td> : null;
                        case 'Customer':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}${index}`}>
                                    <CustomerActions
                                        customer={el}
                                        setRows={setRows as React.Dispatch<React.SetStateAction<ICustomer[]>>}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );
                        case 'Admin':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}${index}`}>
                                    <AdminActions
                                        admin={el}
                                        setRows={setRows}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );
                        case 'Account Manager':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}${index}`}>
                                    <AccountManagerActions
                                        accountManager={el}
                                        setRows={setRows}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );
                        case 'Sourcing Agent':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field} ${index}`}>
                                    <SourcingAgentActions
                                        sourcingAgent={el}
                                        setRows={setRows}
                                        currentPage={currentPage}
                                    />
                                </td>
                            );
                        case 'Call Agent':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <CallAgentActions
                                        callAgent={el}
                                        setRows={setRows}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );
                        case 'CallAgentByCallAdmin':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <CallAgentByCallAdminActions
                                        callAgent={el}
                                        setRows={setRows}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                        callCenter={[]}
                                    />
                                </td>
                            );
                        case 'Payment':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <PaymentActions
                                        payment={el}
                                        setRows={setRows}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );
                        case 'Product':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <ProductActions
                                        product={el}
                                        setRows={setRows}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );
                        case 'ResellerProduct':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <ResellerProductActions
                                        product={el}
                                        setRows={setRows}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );

                        case 'ContentResellerProduct':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <ResellerProductContentActions
                                        product={el}
                                        setRows={setRows}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );

                        case 'CustomerResellerProduct':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <CustomerResellerProductActions product={el} />
                                </td>
                            );
                        case 'OrdersResellerStock':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <OrdersResellerStockActions stock={el} />
                                </td>
                            );
                        case 'Invoice':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    {el.invoiceType === 'PrepaidNoCC' ? (
                                        <PrepaidInvoiceActions
                                            invoice={el}
                                            setRows={setRows}
                                            currentPage={currentPage}
                                            currentFilter={currentFilter}
                                        />
                                    ) : (
                                        <InvoiceActions
                                            invoice={el}
                                            setRows={setRows}
                                            currentPage={currentPage}
                                            currentFilter={currentFilter}
                                            setStats={setStats}
                                        />
                                    )}
                                </td>
                            );
                        case 'Call Center Admin':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <CallCenterAdminActions
                                        callCenterAdmin={el}
                                        setRows={setRows}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                        callCenter={[]}
                                    />
                                </td>
                            );
                        case 'WalletOperation':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <WalletOperationActions
                                        operation={el}
                                        setRows={setRows}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );
                        case 'Purchases':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <PurchasesActions
                                        purchases={el}
                                        setRows={setRows as React.Dispatch<React.SetStateAction<IPurchases[]>>}
                                        currentPage={currentPage}
                                        filter={currentFilter}
                                    />
                                </td>
                            );
                        case 'OrdersForCallAdmin':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <CCAOrderActions
                                        orderData={el}
                                        setRows={setRows as React.Dispatch<React.SetStateAction<IOrder[]>>}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );
                        case 'OrdersForCallAgent':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <CAOrderActions
                                        orderData={el}
                                        setRows={setRows as React.Dispatch<React.SetStateAction<IOrder[]>>}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );
                        case 'OrdersForAdminAndAccountManager':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <OrderActions
                                        orderData={el}
                                        setRows={setRows as React.Dispatch<React.SetStateAction<IOrder[]>>}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );
                        case 'ResellerStock':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <ResellerStockActions
                                        stock={el}
                                        setRows={setRows as React.Dispatch<React.SetStateAction<IResellerStock[]>>}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );
                        case 'Call Center':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <CallCenterActions
                                        callCenter={el}
                                        setRows={setRows}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );

                        case 'CreditActions':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <CreditActions
                                        credit={el}
                                        setRows={setRows}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                        setStats={setStats}
                                    />
                                </td>
                            );

                        case 'LogistioPartnerFeesActions':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <PartnerFeesActions fee={el} setRows={setRows} />
                                </td>
                            );
                        case 'LogistioBulkFeesActions':
                            return header.renderCell ? <td>{header.renderCell(el)}</td> : null;
                        case 'Campaign':
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}${index}`}>
                                    <CampaignActions
                                        campaign={el}
                                        setRows={setRows as React.Dispatch<React.SetStateAction<ICampaign[]>>}
                                        currentPage={currentPage}
                                        currentFilter={currentFilter}
                                    />
                                </td>
                            );
                        default: {
                            return (
                                <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                                    <HiMinus />
                                </td>
                            );
                        }
                    }
                }
                if (header?.fieldType === 'status') {
                    return (
                        <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                            <div className="flex items-center">
                                {icons && icons[el[String(header?.field)]]
                                    ? icons[el[String(header?.field)]].statusIcon
                                    : '--'}{' '}
                            </div>
                        </td>
                    );
                }
                if (header?.fieldType === 'statusOptions') {
                    const status = [8, 21].includes(el[String(header?.field)]) ? 6 : el[String(header?.field)];
                    return (
                        <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                            <div className="flex items-center">
                                <StatusActions
                                    product={el}
                                    setRows={setRows}
                                    currentPage={currentPage}
                                    currentFilter={currentFilter}
                                    Icon={icons && icons[status] ? icons[status].statusIcon : null}
                                    setActiveTab={setActiveTab}
                                />
                            </div>
                        </td>
                    );
                }
                /* when you need to replace a field from data of text or a number with a text
    for example you have a status ot a type as a number and you will display 
    the label correspondant for this field   */
                if (header?.fieldType === 'indentifier') {
                    return (
                        <td className="px-4 py-4" key={`${el._id} ${header.field}`}>
                            <div className="flex items-center">{labels && labels[el[String(header?.field)]]}</div>
                        </td>
                    );
                }
                if (header.fieldType === 'link') {
                    return (
                        <td className="px-4 py-4 max-w-[200px]">
                            {header.icon ? (
                                <a
                                    href={
                                        el[header?.field].startsWith('http:') || el[header?.field].startsWith('https:')
                                            ? el[header?.field]
                                            : `//${el[header?.field]}`
                                    }
                                    target="_blank"
                                    // className="inline-flex items-center justify-center p-5 text-base font-medium text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
                                    rel="noreferrer"
                                >
                                    {header.icon}
                                </a>
                            ) : (
                                el[header?.field] && (
                                    <a
                                        href={
                                            el[header?.field].startsWith('http:') ||
                                            el[header?.field].startsWith('https:')
                                                ? el[header?.field]
                                                : `//${el[header?.field]}`
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                    >
                                        <span className="inline-block overflow-hidden overflow-ellipsis  whitespace-nowrap w-80">
                                            {el[header?.field]}
                                        </span>
                                    </a>
                                )
                            )}
                        </td>
                    );
                }
                if (header.fieldType === 'image') {
                    return (
                        <td className="px-4 py-4">
                            {el[header?.field] && el[header?.field].length > 0 ? (
                                <Avatar img={el[header?.field]} />
                            ) : (
                                <Avatar img={Package} />
                            )}
                            {el[header?.field]}
                            {header.withName && <span className="capitalize my-auto">{el.name}</span>}
                        </td>
                    );
                }
                if (header.fieldType === 'imageArray') {
                    const isThere = el[header?.field]?.filter(
                        (prodImg: any) =>
                            prodImg.endsWith('.jpg') ||
                            prodImg.endsWith('.png') ||
                            prodImg.endsWith('.jpeg') ||
                            prodImg.endsWith('.webp'),
                    );

                    return (
                        <td className="px-4 py-4 flex gap-2">
                            {isThere && isThere.length > 0 ? <Avatar img={isThere[0]} /> : <Avatar img={Package2} />}
                            {header.withName && (
                                <span
                                    title={el.name}
                                    className="capitalize my-auto max-w-[220px] overflow-hidden whitespace-nowrap text-ellipsis"
                                >
                                    {el.name}
                                </span>
                            )}
                        </td>
                    );
                }
                if (header.fieldType === 'profileImage') {
                    return (
                        <td className="px-4 py-4 ">
                            <Avatar img={el[header?.field]} />
                        </td>
                    );
                }
                /* when you need to combine between two fields to display in specific sell
      Example: you need to display the fullName of a customer while,
       you have as an input two fiel firstName and lastName  */
                if (header.fieldType === 'combo') {
                    return (
                        <td
                            className={`px-4 py-4 max-w-[200px] ${header?.classField}`}
                            key={`${el._id} ${header?.field}`}
                        >
                            {header?.child &&
                                header?.child.map((ch: string) => {
                                    return el && el[header?.field] && el[header?.field][ch]
                                        ? ` ${el[header?.field][ch]}`
                                        : '-';
                                })}
                        </td>
                    );
                }
                if (header.fieldType === 'comboFields') {
                    return (
                        <td
                            className={`px-4 py-4 max-w-[200px]  ${header?.classField}`}
                            key={`${el._id} ${header?.field}`}
                        >
                            {header?.child &&
                                header?.child.map((ch: string) => {
                                    return el && el[ch] ? (
                                        <>
                                            <>{el[ch]}</>
                                            <br />
                                        </>
                                    ) : (
                                        <>
                                            <>-</>
                                            <br />
                                        </>
                                    );
                                })}
                        </td>
                    );
                }
                if (header.fieldType === 'render' && header.renderCell) {
                    return (
                        <td className={`px-4 py-4 w-fit ${header?.classField}`} key={`${el._id} ${header?.field}`}>
                            {header.renderCell(el)}
                        </td>
                    );
                }
                if (header.fieldType === 'nestedCombo') {
                    try {
                        const ff = header?.fieldPath?.reduce(
                            (accumulator: any, currentValue: any) => accumulator[currentValue],
                            el,
                        );

                        return (
                            <td
                                className={`px-4 py-4 max-w-[200px] ${header?.classField}`}
                                key={`${el._id} ${header.field}`}
                            >
                                {header?.child &&
                                    header?.child.map((ch: string) => {
                                        return ` ${ff[ch]}`;
                                    })}
                            </td>
                        );
                    } catch (e) {
                        return (
                            <td
                                className={`px-4 py-4 max-w-[200px] ${header?.classField}`}
                                key={`${el._id} ${header.field}`}
                            >
                                --
                            </td>
                        );
                    }
                }
                if (header.fieldType === 'date') {
                    return (
                        <td
                            className={`px-4 py-4 truncate ... min-w-90 hover ${header?.classField}`}
                            key={`${el._id} ${header.field}`}
                        >
                            {moment(
                                new Date(el[header?.field]).toLocaleString('en-US', {
                                    timeZone: 'Asia/Shanghai',
                                }),
                            ).format(header.dateFormat || 'MMMM Do YYYY, HH:mm')}
                        </td>
                    );
                }
                if (header.fieldType === 'currency') {
                    return (
                        <td
                            className={`px-4 py-4 truncate ... max-w-[200px] hover ${header?.classField}`}
                            key={`${el._id} ${header.field}`}
                        >
                            {header?.child &&
                                header?.child.map(() => {
                                    return currencyConverter(
                                        el[header?.field],
                                        el.operationCurrency ? el.operationCurrency : 'USD',
                                    );
                                })}
                        </td>
                    );
                }
                return (
                    <td
                        className={`px-4 py-4 truncate ... max-w-[200px] hover ${header?.classField}`}
                        key={`${el._id} ${header.field}`}
                    >
                        {el[header?.field]}
                    </td>
                );
            })}
        </tr>
    );
};
export default TableRow;
