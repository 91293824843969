import { IProduct } from 'utils/Interfaces/products.interface';
import axios from 'utils/axios';
import { socket } from 'utils/socketIO';
import { getBearerToken, getCurrentUser } from '../../utils/helpers/auth';

export interface Ipagination {
    page: number;
    limit: number;
}

export interface Ifilter {
    name?: string;
    description?: string;
    referenceLink?: string;
    status?: number;
    /**
     * `type` filter is handled by the pagination
     * ad its been used to represent tabulations
     * so no need to be included here
     */
}

export type TProductWithSku = {
    _id: string;
    globalSKU: string;
    product: IProduct;
};

export const getProducts = async (pagination: any) => {
    const config = getBearerToken();
    const response = await axios.post(`/products/filter`, pagination, config);
    return response;
};

export const getProduct = async (id: string) => {
    const config = getBearerToken();
    const response = await axios.get<{ product: IProduct }>(`/products/${id}`, config);
    return response;
};

export const getProductWithSkus = async (productId: string) => {
    const config = getBearerToken();
    const response = await axios.get<IProduct>(`/products/skus/${productId}`, config);
    return response;
};

export const messageFileUpload = async (
    formData: any,
    progressConfig?: {
        onUploadProgress: (ProgressEvent: any) => void;
        onDownloadProgress: (ProgressEvent: any) => void;
    },
) => {
    const config: {
        headers: {
            Authorization: string;
        };
        onUploadProgress?: (progressEvent: any) => void;
        onDownloadProgress?: (progressEvent: any) => void;
    } = getBearerToken();
    if (progressConfig?.onUploadProgress) {
        config.onUploadProgress = progressConfig.onUploadProgress;
    }
    if (progressConfig?.onDownloadProgress) {
        config.onDownloadProgress = progressConfig.onDownloadProgress;
    }

    const response = await axios.post(`/common/upload`, formData, config);
    return response;
};

export const updateProduct = async (
    data: any,
    progressConfig?: {
        onUploadProgress: (ProgressEvent: any) => void;
        onDownloadProgress: (ProgressEvent: any) => void;
    },
) => {
    const config: {
        headers: {
            Authorization: string;
        };
        onUploadProgress?: (progressEvent: any) => void;
        onDownloadProgress?: (progressEvent: any) => void;
    } = getBearerToken();

    if (progressConfig?.onUploadProgress) {
        config.onUploadProgress = progressConfig.onUploadProgress;
    }
    if (progressConfig?.onDownloadProgress) {
        config.onDownloadProgress = progressConfig.onDownloadProgress;
    }

    const response = await axios.put(`/products/${data.id}`, data.updatedData, config);
    if (response.statusText === 'OK' && response.data.productHistoryId) {
        socket.emit('productStatusUpdate', {
            // eslint-disable-next-line no-underscore-dangle
            user: getCurrentUser()._id,
            productHistoryId: response.data.productHistoryId,
        });
    }
    // 🏷️ Return an axios response object w/out the product history id
    return { ...response, data: response.data.updatedProduct };
};

export const updateQuotation = async (
    data: any,
    progressConfig?: {
        onUploadProgress: (ProgressEvent: any) => void;
        onDownloadProgress: (ProgressEvent: any) => void;
    },
) => {
    const config: {
        headers: {
            Authorization: string;
        };
        onUploadProgress?: (progressEvent: any) => void;
        onDownloadProgress?: (progressEvent: any) => void;
    } = getBearerToken();

    if (progressConfig?.onUploadProgress) {
        config.onUploadProgress = progressConfig.onUploadProgress;
    }
    if (progressConfig?.onDownloadProgress) {
        config.onDownloadProgress = progressConfig.onDownloadProgress;
    }

    const response = await axios.put(`/products/quotation/${data.id}`, data.updatedData, config);
    if (response.statusText === 'OK' && response.data.productHistoryId) {
        socket.emit('productStatusUpdate', {
            // eslint-disable-next-line no-underscore-dangle
            user: getCurrentUser()._id,
            productHistoryId: response.data.productHistoryId,
        });
    }
    // 🏷️ Return an axios response object w/out the product history id
    return { ...response, data: response.data.updatedProduct };
};

export const deleteQuotationImage = async (data: { productId: string; imageLink: string }) => {
    const config = getBearerToken();
    const response = await axios.put<string[]>(
        `/products/quotationImage/${data.productId}`,
        { imageLink: data.imageLink },
        config,
    );
    return response;
};

export const deleteQuotationVideo = async (data: { productId: string; videoLink: string }) => {
    const config = getBearerToken();
    const response = await axios.put<string[]>(
        `/products/quotationVideo/${data.productId}`,
        { videoLink: data.videoLink },
        config,
    );
    return response;
};

export interface ISimilarProducts {
    stocked?: boolean;
    country?: string;
    store: string;
    customer: string;
}

export const getSimilarProducts = async (filter: ISimilarProducts) => {
    const similarProducts = await axios.post(`/products/similarProducts`, filter, getBearerToken());
    return similarProducts;
};

export const moveToReseller = async (productId: string) => {
    const config = getBearerToken();
    const response = await axios.post<any>(`/products/moveToReseller/${productId}`, {}, config);
    return response;
};
