import axios from 'utils/axios';
import { getBearerToken } from 'utils/helpers/auth';

export const adminDashboardType = {
    codWithCC: 'COD with call center',
    codWithoutCC: 'COD without call center',
    prepaid: 'Prepaid',
} as const;

export type IAdminDashboardType = (typeof adminDashboardType)[keyof typeof adminDashboardType];
interface IdateRange {
    startDate: string | null | Date;
    endDate: string | null | Date;
}

interface IAdminDashboardSellersInput {
    country?: string;
    type: IAdminDashboardType | string;
    customers?: string[];
    dateRange?: IdateRange;
}

interface IAdminDashboardCallCenterInput {
    countries?: string[];
    dateRange?: IdateRange;
    callCenter?: string;
}

interface IcustomersOutput {
    totalOrdersCount?: number;
    confirmedCount?: number;
    deliveredCount?: number;
    totalPriceConvertedToUSD?: number;
    confirmationRate?: number;
    deliveryRate?: number;
    customerFirstName?: string;
    customerLastName?: string;
}
export interface IadminDashbordOutput {
    customers?: IcustomersOutput[];
    totalOrders?: number;
    averageDeliveryRate?: number;
    averageConfirmationRate?: number;
}

interface IOrderStatusCount {
    status: string;
    count: number;
}

interface ICountryData {
    totalOrdersCount: number;
    confirmedCount: number;
    deliveredCount: number;
    country: string;
}
interface IOrderByCountry {
    totalOrders: number;
    countries: ICountryData;
}

interface IOrderByWeekDays {
    count: number;
    dayOfWeek: string;
}
export interface IadminDahboardCallCenterData {
    ordersStatusCount: IOrderStatusCount[];
    totalOrdersCount: number;
    ordersByCountry: IOrderByCountry[];
    ordersByWeekDays: IOrderByWeekDays[];
}

export const adminDahboardSeller = async (data: IAdminDashboardSellersInput) => {
    const adminDashboardSellerData = await axios.post<IadminDashbordOutput[]>(
        `/dashboard/adminDashboardSeller`,
        data,
        getBearerToken(),
    );
    return adminDashboardSellerData;
};

export const adminDashboardLoigstio = async (data: IAdminDashboardSellersInput) => {
    const adminDashboardLogistioData = await axios.post<IadminDashbordOutput[]>(
        `/dashboard/adminDashboardLogistio`,
        data,
        getBearerToken(),
    );
    return adminDashboardLogistioData;
};

export const adminDahboardCallCenter = async (data: IAdminDashboardCallCenterInput) => {
    const adminDahboardCallCenterData = await axios.post<IadminDahboardCallCenterData>(
        `/dashboard/adminDashboardCallCenter`,
        data,
        getBearerToken(),
    );
    return adminDahboardCallCenterData;
};
