/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import { getCustomers } from 'services/admin/customer';
import { getProducts } from 'services/admin/product';
import AutoComplete from 'components/autocomplete/autoComplete';
import UseDocumentTitle from 'utils/dynamicTitle/dynamicTitle';
import DataTable from 'components/tables/tables';
import { AiOutlineSearch } from 'react-icons/ai';
import { Select, Tabs, TabsRef, TextInput } from 'flowbite-react';
import { ICustomer } from 'pages/customers/customer.types';
import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment-timezone';
import toast from 'react-hot-toast';
import { PRODUCT_PHASES } from 'utils/helpers/status';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { getCurrentUser } from 'utils/helpers/auth';
import { columns, typeDisplay, statusList, statusDisplayColor, ManagerCol, ToTalPriceCol } from './ProductListElements';

interface Ipagination {
    page: number;
    limit: number;
}

interface Ifilter {
    status?: number;
    customer?: string;
    type?: number;
    itemKind: 'product' | 'variant';
    productPhase?: 'Processing' | 'Paid' | 'Rejected';
    search?: string;
    isRestock?: boolean;
    sort?: string;
    isEligibleForReseller?: boolean;
}
interface Imeta {
    totalDocs: number;
    limit: number;
    totalPages: number;
    page: number;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}
export interface IdateRange {
    startDate: string | null | Date;
    endDate: string | null | Date;
}
const ProductList = () => {
    UseDocumentTitle('Products');
    const tabsRef = useRef<TabsRef | null>(null);

    const location = useLocation();
    const isRestockPage = location.pathname.includes('/restock');
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [meta, setMeta] = useState<Partial<Imeta>>({ page: -1 });
    const [filter, setFilter] = useState<Ifilter>({
        itemKind: 'product',
        productPhase: 'Processing',
        isRestock: isRestockPage,
        sort: '-updatedAt',
        isEligibleForReseller: false,
    });
    const [customer] = useState('');

    const [pagination, setPagination] = useState<Ipagination>({
        page: 1,
        limit: 20,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [Rangevalue, setRangeValue] = useState<IdateRange | null>(null);
    useEffect(() => {
        if (customer) getCustomers({ limit: 1000, email: customer }).then((res) => setCustomers(res.data.docs));
        else getCustomers({ limit: 1000 }).then((res) => setCustomers(res.data.docs));
    }, [customer]);

    const handlePaginationChange = (value: { [key: string]: any }) => {
        setPagination((prevPagination) => {
            const data = { ...prevPagination, ...value };
            if (value.limit) {
                data.page = 1;
            }
            return data;
        });
        setMeta((prev) => ({ ...prev, page: -1 }));
    };

    const [productList, setProductList] = useState<any>([]);
    function handleChangeFilter(event: any, isValue?: boolean) {
        if (isValue) setFilter({ ...filter, ...event });
        else if (event.target.value !== '') {
            setFilter({ ...filter, ...{ [event.target.name]: event.target.value } });
        } else {
            setFilter({ ...filter, ...{ [event.target.name]: undefined } });
        }
        setPagination((prevPagination) => ({ ...prevPagination, page: 1 }));
        setMeta({ ...meta, page: -1 });
    }

    function handleChangeFilterCustomer(filterCustomer: string) {
        if (filterCustomer !== '') {
            setFilter({ ...filter, ...{ customer: filterCustomer } });
        } else {
            setFilter({ ...filter, ...{ customer: undefined } });
        }
        setPagination((prevPagination) => ({ ...prevPagination, page: 1 }));
        setMeta({ ...meta, page: -1 });
    }
    const handleRangeFilterChange = (newValue: any) => {
        let newRange: typeof newValue | undefined;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
        }
        setRangeValue(newValue || null);
        setFilter({ ...filter, ...{ dateRange: newRange } });
        setPagination((prevPagination) => ({ ...prevPagination, page: 1 }));
        setMeta({ ...meta, page: -1 });
    };
    const setActiveTab = (phase: string) => tabsRef.current?.setActiveTab(PRODUCT_PHASES.indexOf(phase));
    // const debouncedPagination = useDebouncedValue(pagination, 500);
    // const debouncedFilter = useDebouncedValue(filter, 500);

    useEffect(() => {
        setFilter((prev) => ({ ...prev, isRestock: isRestockPage }));
    }, [location.pathname]);

    useEffect(() => {
        setIsLoading(true);
        const queryOptions = {
            ...pagination,
            ...filter,
            isRestock: isRestockPage,
        };
        getProducts(queryOptions)
            .then((result) => {
                setProductList(result?.data?.docs);
                setMeta({
                    hasNextPage: result.data?.hasNextPage,
                    hasPrevPage: result.data?.hasPrevPage,
                    limit: result.data?.limit,
                    nextPage: result.data?.nextPage,
                    page: result.data?.page,
                    pagingCounter: result.data?.pagingCounter,
                    prevPage: result.data?.prevPage,
                    totalDocs: result.data?.totalDocs,
                    totalPages: result.data?.totalPages,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errors?.message ?? 'Sorry something went wrong');
            });
    }, [pagination, filter]);

    return (
        <div className="bg-white dark:bg-gray-900 p-5">
            <section className="dark:bg-gray-900  items-center my-2">
                <div className="flex  my-4">
                    <div className="flex ">
                        <MdOutlineShoppingCart size={32} />{' '}
                        <span className="font-medium text-2xl">
                            {location.pathname === '/restock' ? 'Restock' : 'Product list'}
                        </span>
                    </div>
                    {/* )} */}
                </div>
                <div className=" relative flex w-full items-center p-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4 justify-between">
                    <div className="flex items-center p-2 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                        <div id="selectStatus">
                            <Select
                                id="productStatus"
                                required
                                value={`${filter.status || ''}_${filter.productPhase}`}
                                onChange={(e) => {
                                    const val = e.target.value ? e.target.value.split('_') : [];
                                    // eslint-disable-next-line no-unused-expressions
                                    val?.length && setActiveTab(val[1]);
                                    handleChangeFilter(
                                        {
                                            status: Number(val[0]) || undefined,
                                            productPhase: val[1] || PRODUCT_PHASES[0],
                                        },
                                        true,
                                    );
                                }}
                                name="status"
                            >
                                {/* <div className="h-8 overflow-x-auto"> */}
                                <option value="">All Status</option>
                                {statusList.map((status) => {
                                    return (
                                        <option key={status.id} value={`${status.id}_${status.type}`}>
                                            {status.label}
                                        </option>
                                    );
                                })}
                                {/* </div> */}
                            </Select>
                        </div>
                        <div className="w-72">
                            <AutoComplete
                                key="id"
                                placeholder="Sellers"
                                options={customers as []}
                                getOptionLabel={(option: any) =>
                                    option?.lastName && option?.firstName
                                        ? `${option.firstName} ${option.lastName}`
                                        : option?.email
                                }
                                // eslint-disable-next-line no-underscore-dangle
                                onChange={(option) => handleChangeFilterCustomer(option._id as string)}
                            />
                        </div>

                        <div className="mb-2 block">
                            <Datepicker
                                inputId="deliveryDate"
                                value={Rangevalue}
                                containerClassName="relative z-100"
                                // inputClassName="absolute"
                                // toggleClassName="absolute
                                useRange
                                onChange={handleRangeFilterChange}
                            />
                        </div>
                    </div>
                    <div>
                        <TextInput
                            icon={AiOutlineSearch}
                            id="search"
                            placeholder="Search by product name or by SKU"
                            name="search"
                            onChange={(e) => {
                                handleChangeFilter(e);
                            }}
                            className="!w-[290px]"
                        />
                    </div>
                </div>
            </section>

            <Tabs
                aria-label="Tabs with icons"
                // eslint-disable-next-line react/style-prop-object
                theme={{
                    tablist: {
                        tabitem: {
                            base: 'flex items-center text-blue-500 justify-center px-4 h-14 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                            styles: {
                                default: {
                                    base: 'flex items-center text-blue-500 justify-center px-4 h-14  text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                    active: {
                                        on: 'text-main border-main border-b-2',
                                        off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300',
                                    },
                                },
                            },
                        },
                    },
                }}
                ref={tabsRef}
                onActiveTabChange={(tab) => {
                    if (!filter.status && PRODUCT_PHASES[tab] === filter.productPhase) return;
                    handleChangeFilter(
                        {
                            productPhase: PRODUCT_PHASES[tab],
                            status: undefined,
                            isEligibleForReseller: PRODUCT_PHASES[tab] === 'Paid',
                        },
                        true,
                    );
                }}
            >
                {PRODUCT_PHASES.map((item: any) => {
                    const cols = [...columns];
                    if (item === 'Paid') cols.splice(4, 0, { ...ToTalPriceCol });
                    if (getCurrentUser()?.role === 'admin') cols.splice(item === 'Paid' ? 5 : 4, 0, { ...ManagerCol });
                    return (
                        <Tabs.Item
                            title={<div className="h-full py-4">{item}</div>}
                            key={item}
                            style={{ height: '64px !important', padding: '16px 24px' }}
                            className="!py-0 xs:!h-14"
                        >
                            <DataTable
                                rows={productList}
                                columns={cols}
                                icons={statusDisplayColor}
                                labels={typeDisplay}
                                setRows={setProductList}
                                setActiveTab={setActiveTab}
                                pageState={{
                                    isLoading,
                                    total: meta.totalDocs,
                                    pageSize: pagination.limit,
                                    page: pagination.page,
                                    count: meta.totalPages,
                                }}
                                setPageState={handlePaginationChange}
                                filterState={filter}
                            />
                        </Tabs.Item>
                    );
                })}
            </Tabs>
        </div>
    );
};

export default ProductList;
