/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { FaBox } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { GiConfirmed } from 'react-icons/gi';
import { TbTruckDelivery } from 'react-icons/tb';
import DataTable from 'components/tables/tables';

import { adminDahboardSeller } from 'services/admin/dashboard';
import { currencyConverter } from 'utils/currencyConverter';
import { dashboardProps } from '../dashboard.types';

export interface IDashboardCustomer {
    totalOrdersCount: number;
    confirmedCount: number;
    deliveredCount: number;
    sellerIncome: number;
    confirmationRate: number;
    deliveryRate: number;
    customerFirstName: string;
    customerLastName: string;
    _id?: string;
}
export interface IadminDashboard {
    customers?: IDashboardCustomer[];
    averageDeliveryRate?: number;
    averageConfirmationRate?: number;
    totalOrders?: number;
    totalConfirmed?: number;
    totalDelivered?: number;
    totalSellerIncome?: number;
}

const SellerPerformance: React.FC<dashboardProps> = ({ filter, activeSeller }) => {
    const [dashboardData, setDashboardData] = useState<IadminDashboard>({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (filter?.type || filter?.country || filter?.dateRange) {
            setIsLoading(true);
            adminDahboardSeller({ ...filter, customers: filter?.customers?.filter((elt) => elt !== 'select_all') })
                .then((res) => {
                    setDashboardData(res?.data?.[0] as {});
                    setIsLoading(false);
                })
                .catch(() => {
                    toast.error('Something went wrong');
                });
        }
    }, [filter]);

    const getAdminDashboardColumns = (filterType: string) => {
        return [
            {
                field: 'customerLastName',
                headerName: 'Seller Name',
                fieldType: 'render',
                renderCell: (params: IDashboardCustomer) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">
                        {params.customerFirstName} {params.customerLastName}
                    </p>
                ),
            },
            {
                field: 'totalOrdersCount',
                headerName: 'Total Number of orders',
                fieldType: 'render',
                renderCell: (params: IDashboardCustomer) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">{params.totalOrdersCount}</p>
                ),
            },
            filterType === 'COD with call center' && {
                field: 'confirmedCount',
                headerName: 'Confirmed orders',
                fieldType: 'render',
                renderCell: (params: IDashboardCustomer) => (
                    <div className="w-full">
                        {params.confirmedCount ? (
                            <div className="flex  items-center w-full">
                                <div className="text-m font-bold text-gray-700 dark:text-gray-300 w-16">
                                    {params?.confirmedCount}
                                </div>
                                <div
                                    style={{ color: params?.confirmationRate >= 60 ? 'green' : 'red' }}
                                    className="bg-gray-200 rounded-lg p-1 flex items-center justify-center text-s"
                                >
                                    {params?.confirmationRate}%
                                </div>
                            </div>
                        ) : (
                            <p className="text-m font-bold text-gray-700 dark:text-gray-300 w-16">0</p>
                        )}
                    </div>
                ),
            },
            {
                field: 'deliveredCount',
                headerName: 'Delivered orders',
                fieldType: 'render',
                renderCell: (params: IDashboardCustomer) => (
                    <div className="w-full">
                        {params.deliveredCount ? (
                            <div className="flex  items-center w-full">
                                <div className="text-m font-bold text-gray-700 dark:text-gray-300 w-16">
                                    {params?.deliveredCount}
                                </div>
                                <div
                                    style={{ color: params?.deliveryRate >= 50 ? 'green' : 'red' }}
                                    className="bg-gray-200 rounded-lg p-1 flex items-center justify-center text-s"
                                >
                                    {params?.deliveryRate}%
                                </div>
                            </div>
                        ) : (
                            <p className="text-m font-bold text-gray-700 dark:text-gray-300 w-16">0</p>
                        )}
                    </div>
                ),
            },
            filterType !== 'Prepaid' && {
                field: 'sellerIncome',
                headerName: 'Seller Income ($)',
                fieldType: 'render',
                renderCell: (params: IDashboardCustomer) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">
                        {params.sellerIncome ? `${currencyConverter(params.sellerIncome, 'USD')}` : '$0'}
                    </p>
                ),
            },
        ].filter(Boolean);
    };

    return (
        <section className="flex flex-col   shadow-sx bg-white dark:bg-gray-800  w-full p-4">
            <div className="flex ">
                <p className="mb-2 text-m font-semibold text-black-500 dark:text-black-200">Top performing sellers:</p>
                <p className="text-m font-normal text-gray-500 dark:text-gray-200 pl-1">
                    {dashboardData?.customers?.length || 0} out of {activeSeller} active sellers
                </p>
            </div>

            {!isLoading && (
                <div className="flex center justify-between w-full gap-4 my-6">
                    <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                        <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                            <FaBox className="w-6 h-6" />
                        </div>
                        <div>
                            <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">Total Orders</p>
                            <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                                {dashboardData?.totalOrders || 0}
                            </p>
                        </div>
                    </div>
                    {filter?.type === 'COD with call center' && (
                        <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                            <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                                <GiConfirmed className="w-6 h-6" />
                            </div>
                            <div>
                                <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">
                                    Total Confirmed Orders
                                </p>

                                <div className="flex  items-center w-full">
                                    <div className="text-m font-bold text-gray-700 dark:text-gray-300 w-14">
                                        {dashboardData?.totalConfirmed || 0}
                                    </div>
                                    <div className="bg-gray-200 rounded-lg p-1 flex items-center justify-center text-s">
                                        <p
                                            style={{
                                                color:
                                                    (dashboardData?.averageConfirmationRate || 0) >= 60
                                                        ? 'green'
                                                        : 'red',
                                            }}
                                            className="text-l font-bold text-gray-700 dark:text-gray-300"
                                        >
                                            {dashboardData?.averageConfirmationRate || 0} %
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                        <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                            <TbTruckDelivery className="w-6 h-6 " />
                        </div>
                        <div>
                            <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">
                                Total Delivered Orders
                            </p>
                            <div className="flex  items-center w-full">
                                <div className="text-m font-bold text-gray-700 dark:text-gray-300 w-14">
                                    {dashboardData?.totalDelivered || 0}
                                </div>
                                <div className="bg-gray-200 rounded-lg p-1 flex items-center justify-center text-s">
                                    <p
                                        style={{
                                            color: (dashboardData?.averageDeliveryRate || 0) >= 50 ? 'green' : 'red',
                                        }}
                                        className="text-l font-bold text-gray-700 dark:text-gray-300"
                                    >
                                        {dashboardData?.averageDeliveryRate || 0} %
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {filter?.type !== 'Prepaid' && (
                        <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                            <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                                <FaBox className="w-6 h-6" />
                            </div>
                            <div>
                                <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">
                                    Total seller income
                                </p>
                                <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                                    {currencyConverter(dashboardData?.totalSellerIncome || 0, 'USD')}
                                </p>
                            </div>
                        </div>
                    )}
                    {filter?.type !== 'COD with call center' && <div className="p-4 flex items-center w-full " />}
                </div>
            )}

            <div>
                <DataTable
                    pageState={{
                        isLoading,
                        total: 0,
                        pageSize: 0,
                        page: 0,
                        count: 0,
                    }}
                    setPageState={() => {}}
                    setRows={setDashboardData}
                    rows={dashboardData?.customers || []}
                    columns={getAdminDashboardColumns(filter?.type) as any}
                    filterState={filter}
                />
            </div>
        </section>
    );
};

export default SellerPerformance;
