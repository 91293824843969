/* eslint-disable no-underscore-dangle */
import CustomBtn from 'components/customButton/customBtn';
import GeneralDrawer from 'components/generalDrawer/generalDrawer';
import { Alert, TextInput } from 'flowbite-react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { HiInformationCircle } from 'react-icons/hi';

import { MdModeEdit } from 'react-icons/md';

import {
    getLogistioPartnerFeesList,
    ILogistioPartnersFees,
    IPricingLogistioPartnersFees,
    updateLogistioPartnerFees,
} from 'services/admin/logistioPartnersFees';

interface IPartnerFeesActions {
    fee: ILogistioPartnersFees;
    setRows: any;
}

const PartnerFeesActions = (props: IPartnerFeesActions) => {
    const { setRows, fee } = props;
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [editedPricing, setEditedPricing] = useState<IPricingLogistioPartnersFees[]>(fee?.pricing);

    const refetchGetFees = () => {
        getLogistioPartnerFeesList().then((res) => {
            setRows(res?.data || []);
            setIsLoading(false);
        });
    };

    const handleSave = () => {
        setIsLoading(true);
        updateLogistioPartnerFees(fee._id, { pricing: editedPricing })
            .then((res) => {
                toast.success(res.data.message);
                refetchGetFees();
            })
            .catch((err) => {
                toast.error(err.response.data.errors.message);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCancel = () => {
        setOpenEdit(false);
        setEditedPricing(fee?.pricing);
        setErrors({});
    };

    const handleReset = () => {
        setEditedPricing(fee?.pricing);
        setErrors({});
    };

    const handleUpdatePricing = (value: string | number, target: string, index: number) => {
        setEditedPricing((prevState) => {
            const updatedPricing = [...prevState];
            const errorKey = `${index}_${target}`;
            if (updatedPricing[index]) {
                updatedPricing[index] = {
                    ...updatedPricing[index],
                    [target]: value,
                };

                if (Number(value) >= 0) {
                    setErrors((prevErrors) => {
                        const updatedErrors = { ...prevErrors };
                        delete updatedErrors[errorKey];
                        return updatedErrors;
                    });
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [errorKey]: `${target} must be a valid  number`,
                    }));
                }
            }

            return updatedPricing;
        });
    };

    const priceTable = (pricing: IPricingLogistioPartnersFees[]) => {
        const countries = Object.keys(pricing);

        return (
            <div className="overflow-x-auto py-2">
                <table className="min-w-full bg-white dark:bg-gray-700 border border-x-0 border-y-gray-300">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th className="py-1 px-2 text-left border border-x-0 border-y-gray-300" />
                            {pricing.map((elt: any) => (
                                <th
                                    key={elt.country}
                                    className="py-2 px-2 text-center border border-x-0 border-y-gray-300"
                                >
                                    {elt.country}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="py-4 px-2 text-left  font-bold">Call Center</td>
                        </tr>
                        <tr>
                            <td className="py-2 px-2 text-left border border-x-0 border-y-gray-300">
                                Entered lead ($)
                            </td>
                            {countries.map((country) => (
                                <td
                                    key={`${country}-newLeadPrice`}
                                    className="py-2 px-2 text-center border border-x-0 border-y-gray-300"
                                >
                                    <TextInput
                                        color={errors[`${Number(country)}_newLeadPrice`] ? 'failure' : 'gray'}
                                        type="text"
                                        name="newLeadPrice"
                                        key={`${pricing[Number(country)]}_newLeadPrice`}
                                        placeholder="New Lead Price"
                                        value={pricing[Number(country)].newLeadPrice}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdatePricing(e.target.value, 'newLeadPrice', Number(country));
                                        }}
                                    />
                                    {errors[`${Number(country)}_newLeadPrice`] && (
                                        <Alert
                                            color="failure"
                                            icon={HiInformationCircle}
                                            className="flex items-center h-full py-0 px-2 text-sm  overflow-hidden"
                                        >
                                            <span>Error: Field must be a number</span>
                                        </Alert>
                                    )}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td className="py-2 px-2 text-left border border-x-0 border-y-gray-300">
                                Confirmed lead ($)
                            </td>
                            {countries.map((country) => (
                                <td
                                    key={`${country}-confirmedLeadPrice`}
                                    className="py-2 px-2 text-center border border-x-0 border-y-gray-300"
                                >
                                    <TextInput
                                        key={`${pricing[Number(country)]}_confirmedLeadPrice`}
                                        color={errors[`${Number(country)}_confirmedLeadPrice`] ? 'failure' : 'gray'}
                                        type="text"
                                        name="confirmedLeadPrice"
                                        placeholder="Confirmed Lead Price"
                                        value={pricing[Number(country)].confirmedLeadPrice}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdatePricing(e.target.value, 'confirmedLeadPrice', Number(country));
                                        }}
                                    />

                                    {errors[`${Number(country)}_confirmedLeadPrice`] && (
                                        <Alert
                                            color="failure"
                                            icon={HiInformationCircle}
                                            className="flex items-center h-full py-0 px-2 text-sm  overflow-hidden"
                                        >
                                            <span>Error: Field must be a number</span>
                                        </Alert>
                                    )}
                                </td>
                            ))}
                        </tr>

                        <tr>
                            <td className="py-2 px-2 text-left border border-x-0 border-y-gray-300">
                                Delivered lead ($)
                            </td>
                            {countries.map((country) => (
                                <td
                                    key={`${country}-deliveredLeadPrice`}
                                    className="py-2 px-2 text-center border border-x-0 border-y-gray-300"
                                >
                                    <TextInput
                                        color={errors[`${Number(country)}_deliveredLeadPrice`] ? 'failure' : 'gray'}
                                        type="text"
                                        key={`${pricing[Number(country)]}_deliveredLeadPrice`}
                                        name="deliveredLeadPrice"
                                        placeholder="Delivered price"
                                        value={pricing[Number(country)].deliveredLeadPrice}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdatePricing(e.target.value, 'deliveredLeadPrice', Number(country));
                                        }}
                                    />
                                    {errors[`${Number(country)}_deliveredLeadPrice`] && (
                                        <Alert
                                            color="failure"
                                            icon={HiInformationCircle}
                                            className="flex items-center h-full py-0 px-2 text-sm  overflow-hidden"
                                        >
                                            <span>Error: Field must be a number</span>
                                        </Alert>
                                    )}
                                </td>
                            ))}
                        </tr>

                        <tr>
                            <td className="py-2 px-2 text-left border border-x-0 border-y-gray-300">
                                Up Down Cross sell ($)
                            </td>
                            {countries.map((country) => (
                                <td
                                    key={`${country}-upDownCross`}
                                    className="py-2 px-2 text-center border border-x-0 border-y-gray-300"
                                >
                                    <TextInput
                                        color={errors[`${Number(country)}_upDownCross`] ? 'failure' : 'gray'}
                                        type="text"
                                        name="upDownCross"
                                        key={`${pricing[Number(country)]}_upDownCross`}
                                        placeholder="up Down Cross"
                                        value={pricing[Number(country)].upDownCross}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdatePricing(e.target.value, 'upDownCross', Number(country));
                                        }}
                                    />
                                    {errors[`${Number(country)}_upDownCross`] && (
                                        <Alert
                                            color="failure"
                                            icon={HiInformationCircle}
                                            className="flex items-center h-full py-0 px-2 text-sm  overflow-hidden"
                                        >
                                            <span>Error: Field must be a number</span>
                                        </Alert>
                                    )}
                                </td>
                            ))}
                        </tr>

                        <tr>
                            <td className="py-4 px-2 text-left  font-bold">Shipping</td>
                        </tr>

                        <tr>
                            <td className="py-2 px-2 text-left border border-x-0 border-y-gray-300">
                                Shipped & delivered ($)
                            </td>
                            {countries.map((country) => (
                                <td
                                    key={`${country}-shippedAndDeliveredPrice`}
                                    className="py-2 px-2 text-center border border-x-0 border-y-gray-300"
                                >
                                    <TextInput
                                        color={
                                            errors[`${Number(country)}_shippedAndDeliveredPrice`] ? 'failure' : 'gray'
                                        }
                                        type="text"
                                        name="shippedAndDeliveredPrice"
                                        key={`${pricing[Number(country)]}_shippedAndDeliveredPrice`}
                                        placeholder="shipped And Delivered Price"
                                        value={pricing[Number(country)].shippedAndDeliveredPrice}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdatePricing(
                                                e.target.value,
                                                'shippedAndDeliveredPrice',
                                                Number(country),
                                            );
                                        }}
                                    />

                                    {errors[`${Number(country)}_shippedAndDeliveredPrice`] && (
                                        <Alert
                                            color="failure"
                                            icon={HiInformationCircle}
                                            className="flex items-center h-full py-0 px-2 text-sm  overflow-hidden"
                                        >
                                            <span>Error: Field must be a number</span>
                                        </Alert>
                                    )}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td className="py-2 px-2 text-left border border-x-0 border-y-gray-300">
                                Shipped & Returned ($)
                            </td>
                            {countries.map((country) => (
                                <td
                                    key={`${country}-shippedAndReturnedPrice`}
                                    className="py-2 px-2 text-center border border-x-0 border-y-gray-300"
                                >
                                    <TextInput
                                        color={
                                            errors[`${Number(country)}_shippedAndReturnedPrice`] ? 'failure' : 'gray'
                                        }
                                        type="text"
                                        name="shippedAndReturnedPrice"
                                        key={`${pricing[Number(country)]}_shippedAndReturnedPrice`}
                                        placeholder="shipped And Returned Price"
                                        value={pricing[Number(country)].shippedAndReturnedPrice}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdatePricing(
                                                e.target.value,
                                                'shippedAndReturnedPrice',
                                                Number(country),
                                            );
                                        }}
                                    />

                                    {errors[`${Number(country)}_shippedAndReturnedPrice`] && (
                                        <Alert
                                            color="failure"
                                            icon={HiInformationCircle}
                                            className="flex items-center h-full py-0 px-2 text-sm  overflow-hidden"
                                        >
                                            <span>Error: Field must be a number</span>
                                        </Alert>
                                    )}
                                </td>
                            ))}
                        </tr>

                        <tr>
                            <td className="py-2 px-2 text-left border border-x-0 border-y-gray-300">COD (%)</td>
                            {countries.map((country) => (
                                <td
                                    key={`${country}-percentFromSellPrice`}
                                    className="py-2 px-2 text-center border border-x-0 border-y-gray-300"
                                >
                                    <TextInput
                                        color={errors[`${Number(country)}_percentFromSellPrice`] ? 'failure' : 'gray'}
                                        type="text"
                                        name="percentFromSellPrice"
                                        key={`${pricing[Number(country)]}_percentFromSellPrice`}
                                        placeholder="Percent from sell price"
                                        value={pricing[Number(country)].percentFromSellPrice}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdatePricing(
                                                e.target.value,
                                                'percentFromSellPrice',
                                                Number(country),
                                            );
                                        }}
                                    />

                                    {errors[`${Number(country)}_percentFromSellPrice`] && (
                                        <Alert
                                            color="failure"
                                            icon={HiInformationCircle}
                                            className="flex items-center h-full py-0 px-2 text-sm  overflow-hidden"
                                        >
                                            <span>Error: Field must be a number</span>
                                        </Alert>
                                    )}
                                </td>
                            ))}
                        </tr>

                        <tr>
                            <td className="py-2 px-2 text-left border border-x-0 border-y-gray-300">
                                Handling per unit
                            </td>
                            {countries.map((country) => (
                                <td
                                    key={`${country}-handlingPerUnit`}
                                    className="py-2 px-2 text-center border border-x-0 border-y-gray-300"
                                >
                                    <TextInput
                                        color={errors[`${Number(country)}_handlingPerUnit`] ? 'failure' : 'gray'}
                                        type="text"
                                        name="handlingPerUnit"
                                        key={`${pricing[Number(country)]}_handlingPerUnit`}
                                        placeholder="Handling per unit"
                                        value={pricing[Number(country)].handlingPerUnit}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdatePricing(e.target.value, 'handlingPerUnit', Number(country));
                                        }}
                                    />

                                    {errors[`${Number(country)}_handlingPerUnit`] && (
                                        <Alert
                                            color="failure"
                                            icon={HiInformationCircle}
                                            className="flex items-center h-full py-0 px-2 text-sm  overflow-hidden"
                                        >
                                            <span>Error: Field must be a number</span>
                                        </Alert>
                                    )}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <>
            <MdModeEdit
                onClick={() => setOpenEdit(true)}
                className="w-5 h-5 hover:cursor-pointer dark:text-gray-400  text-gray-700"
            />

            {openEdit && (
                <GeneralDrawer
                    key={fee._id}
                    anchor="right"
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    PaperProps={{
                        sx: { width: '70%', padding: '10px' },
                    }}
                >
                    <div className="px-2">
                        <h4 className="capitalize pl-1">{fee.feeType}</h4>
                    </div>

                    <div className="flex flex-col justify-between px-2 py-1">
                        <div className="py-1">{priceTable(editedPricing)}</div>

                        <div className="mt-4 flex justify-end gap-2">
                            <CustomBtn
                                type="button"
                                variant="secondary"
                                onClick={handleReset}
                                isProcessing={isLoading}
                                disabled={isLoading}
                            >
                                Reset
                            </CustomBtn>
                            <CustomBtn
                                type="button"
                                variant="secondary"
                                onClick={handleCancel}
                                isProcessing={isLoading}
                                disabled={isLoading}
                            >
                                Cancel
                            </CustomBtn>
                            <CustomBtn
                                onClick={handleSave}
                                type="submit"
                                variant="primary"
                                isProcessing={isLoading}
                                disabled={isLoading}
                            >
                                Save
                            </CustomBtn>
                        </div>
                    </div>
                </GeneralDrawer>
            )}
        </>
    );
};
export default PartnerFeesActions;
