/* eslint-disable no-underscore-dangle */
import Tablesku from 'components/listSkus/TableSku';
import { Button, Dropdown, Modal } from 'flowbite-react';
import { IProductActions } from 'pages/productList/product.types';
import React, { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { HiChat, HiDotsVertical, HiPencil } from 'react-icons/hi';
import { IoIosBarcode } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'utils/helpers/auth';
import { IProduct } from 'utils/Interfaces/products.interface';
import { MdCardGiftcard } from 'react-icons/md';
import { moveToReseller } from 'services/admin/product';

const ProductActions = (props: IProductActions) => {
    const [openModal, setOpenModal] = useState({ skus: false, moveToResller: false });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { product } = props;

    const handleShowSkus = () => {
        if (
            (product.variants.length === 0 && !product.globalSKU) ||
            (product.variants as IProduct[]).some((variant) => !variant.globalSKU)
        ) {
            toast.error('Sorry, something went wrong. Please try again!');
        } else {
            setOpenModal({ skus: true, moveToResller: false });
        }
    };

    const confirmMoveToReseller = () => {
        if (product.isEligibleForReseller) {
            setOpenModal({ skus: false, moveToResller: true });
        } else {
            toast.error('Sorry, this product can"t be moved to reseller!');
        }
    };

    const handleMoveToReseller = () => {
        setIsLoading(true);
        moveToReseller(product._id)
            .then(() => {
                setOpenModal({ skus: false, moveToResller: false });
                toast.success('Product added successfully to reseller');
            })
            .catch((err) => {
                setOpenModal({ skus: false, moveToResller: false });
                toast.error(err?.response?.data?.errors?.message);
            })
            .finally(() => setIsLoading(false));
    };

    const navigateEditProduct = () => {
        const user = getCurrentUser();
        let navigation = 'admin';
        if (user.role === 'accountManager') {
            navigation = 'accountManager';
        }
        if (user.role === 'productProvider') {
            navigation = 'productProvider';
        }
        navigate(`/${navigation}/product-settings`, {
            state: {
                tabId: 0,
                productId: product._id,
                type: product.type,
                customerId: product.customer,
                productName: product.name,
                isRestock: pathname.includes('restock'),
            },
        });
    };
    const navigateChatProduct = () => {
        const user = getCurrentUser();
        let navigation = 'admin';
        if (user.role === 'accountManager') {
            navigation = 'accountManager';
        }
        if (user.role === 'productProvider') {
            navigation = 'productProvider';
        }
        navigate(`/${navigation}/product-settings`, {
            state: {
                tabId: 2,
                productId: product._id,
                type: product.type,
                customerId: product.customer,
                productName: product.name,
            },
        });
    };

    const getNavigationList = useCallback(() => {
        if (product.isEligibleForReseller) {
            return [
                {
                    label: 'Product details',
                    action: navigateEditProduct,
                    iconAcion: HiPencil,
                },
                {
                    label: 'Preview SKU',
                    action: () => handleShowSkus(),
                    iconAcion: IoIosBarcode,
                },
                {
                    label: 'Chat',
                    action: navigateChatProduct,
                    iconAcion: HiChat,
                },
                {
                    label: 'Move to reseller',
                    action: () => confirmMoveToReseller(),
                    iconAcion: MdCardGiftcard,
                },
            ];
        }

        return [
            {
                label: 'Product details',
                action: navigateEditProduct,
                iconAcion: HiPencil,
            },
            {
                label: 'Preview SKU',
                action: () => handleShowSkus(),
                iconAcion: IoIosBarcode,
            },
            {
                label: 'Chat',
                action: navigateChatProduct,
                iconAcion: HiChat,
            },
        ];
    }, [product.isEligibleForReseller]);

    return (
        <>
            {openModal.skus && (
                <Modal
                    dismissible
                    show={openModal.skus}
                    popup
                    onClose={() => setOpenModal({ skus: false, moveToResller: false })}
                    theme={{
                        content: {
                            base: 'w-fit',
                            inner: 'relative rounded-lg bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh] w-fit',
                        },
                    }}
                >
                    {' '}
                    <Modal.Header className="p-4 pb-0">Product Skus - {product?.name || '--'}</Modal.Header>
                    <Modal.Body className=" flex !p-4 ">
                        <Tablesku product={product} />
                    </Modal.Body>
                </Modal>
            )}

            {openModal.moveToResller && (
                <Modal
                    dismissible
                    show={openModal.moveToResller}
                    popup
                    onClose={() => setOpenModal({ skus: false, moveToResller: false })}
                    theme={{
                        content: {
                            base: 'w-fit',
                            inner: 'relative rounded-lg bg-white shadow dark:bg-gray-700 flex flex-col max-h-[120vh] w-fit',
                        },
                    }}
                >
                    <Modal.Header className="p-4 pb-0">Move {product?.name || '--'} to reseller</Modal.Header>
                    <Modal.Body className=" flex items-center min-h-[200px] min-w-[450px]">
                        <div className="text-center">
                            <p className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                Are you sure you want to move this product to reseller ?
                            </p>
                            <div className=" flex items-center justify-around ">
                                <Button
                                    disabled={isLoading}
                                    onClick={() => setOpenModal({ moveToResller: false, skus: false })}
                                    color="light"
                                >
                                    Cancel
                                </Button>

                                <Button disabled={isLoading} onClick={handleMoveToReseller} color="blue">
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}

            <Dropdown inline arrowIcon={false} label={<HiDotsVertical />}>
                {getNavigationList().map((navigation) => {
                    return (
                        <Dropdown.Item icon={navigation.iconAcion} onClick={navigation.action} key={navigation.label}>
                            {navigation.label}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>
        </>
    );
};
export default ProductActions;
