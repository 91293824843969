/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { FaBox } from 'react-icons/fa';
import toast from 'react-hot-toast';

import DataTable from 'components/tables/tables';

import { adminDashboardLoigstio } from 'services/admin/dashboard';
import { currencyConverter } from 'utils/currencyConverter';
import { dashboardProps } from '../dashboard.types';

export interface IadminDashboard {
    totalOrdersCount: number;
    confirmedCount: number;
    deliveredCount: number;
    grossProfit: number;
    confirmationRate: number;
    deliveryRate: number;
    customerFirstName: string;
    customerLastName: string;
    ndr: number;
    _id?: string;

    // to remove
    profit?: number;
    totalSales?: number;
    logistioIncome?: number;
    shippingFees?: number;
    callCenterFees?: number;
    handleFees?: number;
    prepaidFees?: number;
    unpaidBalance?: number;
    resellerProductsCost?: number;
}

const LogistioPerformance: React.FC<dashboardProps> = ({ filter }) => {
    const [dashboardData, setDashboardData] = useState<IadminDashboard[] | any>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (filter?.type || filter?.country || filter?.dateRange) {
            setIsLoading(true);
            adminDashboardLoigstio({ ...filter, customers: filter?.customers?.filter((elt) => elt !== 'select_all') })
                .then((res) => {
                    setDashboardData(res?.data);
                    setIsLoading(false);
                })
                .catch(() => {
                    toast.error('Something went wrong');
                });
        }
    }, [filter]);

    const getAdminDashboardColumns = (filterType: string) => {
        return [
            {
                field: 'customerLastName',
                headerName: 'Seller Name',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">
                        {params.customerFirstName} {params.customerLastName}
                    </p>
                ),
            },
            {
                field: 'totalOrdersCount',
                headerName: 'Total Number of orders',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">{params.totalOrdersCount}</p>
                ),
            },
            filterType === 'COD with call center' && {
                field: 'confirmedCount',
                headerName: 'Confirmed orders',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <div className="w-full">
                        {params.confirmedCount ? (
                            <div className="flex  items-center w-full">
                                <div className="text-m font-bold text-gray-700 dark:text-gray-300 w-16">
                                    {params?.confirmedCount}
                                </div>
                                <div
                                    style={{ color: params?.confirmationRate >= 60 ? 'green' : 'red' }}
                                    className="bg-gray-200 rounded-lg p-1 flex items-center justify-center text-s"
                                >
                                    {params?.confirmationRate}%
                                </div>
                            </div>
                        ) : (
                            <p className="text-m font-bold text-gray-700 dark:text-gray-300 w-16">0</p>
                        )}
                    </div>
                ),
            },
            {
                field: 'deliveredCount',
                headerName: 'Delivered orders',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <div className="w-full">
                        {params.deliveredCount ? (
                            <div className="flex  items-center w-full">
                                <div className="text-m font-bold text-gray-700 dark:text-gray-300 w-16">
                                    {params?.deliveredCount}
                                </div>
                                <div
                                    style={{ color: params?.deliveryRate >= 50 ? 'green' : 'red' }}
                                    className="bg-gray-200 rounded-lg p-1 flex items-center justify-center text-s"
                                >
                                    {params?.deliveryRate}%
                                </div>
                            </div>
                        ) : (
                            <p className="text-m font-bold text-gray-700 dark:text-gray-300 w-16">0</p>
                        )}
                    </div>
                ),
            },

            {
                field: 'ndr',
                headerName: 'NDR',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <div className="w-full">
                        {params.ndr ? (
                            <div className="flex  items-center w-full">
                                <div className="text-m  text-gray-700 dark:text-gray-300 w-16">
                                    <div
                                        style={{ color: params?.ndr >= 50 ? 'green' : 'red' }}
                                        className="bg-gray-200 rounded-lg p-1 flex items-center justify-center text-s"
                                    >
                                        {params?.ndr}%
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p className="text-m  text-gray-700 dark:text-gray-300 w-16">0</p>
                        )}
                    </div>
                ),
            },

            /* to remove */
            filterType !== 'Prepaid' && {
                field: 'totalSales',
                headerName: 'total sales ($)',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">
                        {params.totalSales ? `${currencyConverter(params.totalSales, 'USD')}` : '$0'}
                    </p>
                ),
            },

            filterType !== 'Prepaid' && {
                field: 'profit',
                headerName: 'Seller Income ($)',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">
                        {params?.profit && Number(params?.profit) > 0
                            ? `${currencyConverter(params.profit, 'USD')}`
                            : '$0'}
                    </p>
                ),
            },

            filterType !== 'Prepaid' && {
                field: 'unpaidBalance',
                headerName: 'unpaid Balance ($)',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">
                        {params.unpaidBalance ? `${currencyConverter(Math.abs(params.unpaidBalance), 'USD')}` : '$0'}
                    </p>
                ),
            },

            filterType !== 'Prepaid' && {
                field: 'logistioIncome',
                headerName: 'Logistio Income ($)',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">
                        {params.logistioIncome ? `${currencyConverter(params.logistioIncome, 'USD')}` : '$0'}
                    </p>
                ),
            },

            filterType === 'COD without call center' && {
                field: 'handleFees',
                headerName: 'Handle fees ($)',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">
                        {params.handleFees ? `${currencyConverter(params.handleFees, 'USD')}` : '$0'}
                    </p>
                ),
            },

            filterType === 'Prepaid' && {
                field: 'prepaidFees',
                headerName: 'prepaid fees ($)',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">
                        {params.prepaidFees ? `${currencyConverter(params.prepaidFees, 'USD')}` : '$0'}
                    </p>
                ),
            },

            {
                field: 'shippingFees',
                headerName: 'Shipping fees ($)',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">
                        {params.shippingFees ? `${currencyConverter(params.shippingFees, 'USD')}` : '$0'}
                    </p>
                ),
            },

            filterType === 'COD with call center' && {
                field: 'callCenterFees',
                headerName: 'Call center fees ($)',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">
                        {params.callCenterFees ? `${currencyConverter(params.callCenterFees, 'USD')}` : '$0'}
                    </p>
                ),
            },

            {
                field: 'resellerProductsCost',
                headerName: 'Reseller product cost ($)',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">
                        {params.resellerProductsCost
                            ? `${currencyConverter(params.resellerProductsCost, 'USD')}`
                            : '$0'}
                    </p>
                ),
            },

            /* */

            {
                field: 'grossProfit',
                headerName: 'Gross profit ($)',
                fieldType: 'render',
                renderCell: (params: IadminDashboard) => (
                    <p className="text-m font-bold text-gray-700 dark:text-gray-300">
                        {params.grossProfit ? `${currencyConverter(params.grossProfit, 'USD')}` : '$0'}
                    </p>
                ),
            },
        ].filter(Boolean);
    };

    return (
        <section className="flex flex-col   shadow-sx bg-white dark:bg-gray-800  w-full p-4">
            {!isLoading && (
                <div className="flex center justify-between w-full gap-4 my-6">
                    <div className="p-4 flex items-center w-full border border-grey rounded-lg">
                        <div className="p-4 rounded-md text-purple-500 dark:text-purple-100 bg-purple-100 dark:bg-purple-500 mr-4">
                            <FaBox className="w-6 h-6" />
                        </div>
                        <div>
                            <p className="mb-2 text-m font-normal text-gray-500 dark:text-gray-500">
                                Total gross profit
                            </p>
                            <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
                                {currencyConverter(
                                    dashboardData?.reduce(
                                        (acc: number, elt: IadminDashboard) => acc + elt.grossProfit,
                                        0,
                                    ) || 0,
                                    'USD',
                                )}
                            </p>
                        </div>
                    </div>

                    <div className="p-4 flex items-center w-full " />
                    <div className="p-4 flex items-center w-full " />
                </div>
            )}

            <div>
                <DataTable
                    pageState={{
                        isLoading,
                        total: 0,
                        pageSize: 0,
                        page: 0,
                        count: 0,
                    }}
                    setPageState={() => {}}
                    setRows={setDashboardData}
                    rows={dashboardData || []}
                    columns={getAdminDashboardColumns(filter?.type) as any}
                    filterState={filter}
                />
            </div>
        </section>
    );
};

export default LogistioPerformance;
