import React from 'react';
import { TableColDef } from 'components/tables/tables.types';
import { format } from 'date-fns';
import { IUser } from 'services/auth';
import profileImg from 'assets/avatar.png';
import { ILogistioPartnersFees } from 'services/admin/logistioPartnersFees';

export const logistioBulkPartnerColumn: TableColDef<ILogistioPartnersFees>[] = [
    {
        field: 'createdAt',
        headerName: 'Created At',
        fieldType: 'render',
        classHeader: 'text-center',
        renderCell: (params: ILogistioPartnersFees) => (
            <div className="px-3 py-1 text-sm font-medium text-gray-700 bg-gray-100 rounded-full w-fit">
                <p>{`${params?.createdAt ? format(new Date(params?.createdAt), 'dd/MM/yyyy') : '-'} `}</p>
            </div>
        ),
    },

    {
        field: 'feeType',
        headerName: 'Item',
        fieldType: 'render',
        classHeader: 'text-center',
        renderCell: (params: ILogistioPartnersFees) => (
            <p className="capitalize text-gray-700 dark:text-white">{`${params?.feeType || ''} `}</p>
        ),
    },

    {
        field: 'updatedAt',
        headerName: 'Updated At',
        fieldType: 'render',
        classHeader: 'text-center',
        renderCell: (params: ILogistioPartnersFees) => (
            <div className="px-3 py-1 text-sm font-medium text-gray-700 bg-gray-100 rounded-full w-fit">
                <p>{`${params?.updatedAt ? format(new Date(params?.updatedAt), 'dd/MM/yyyy') : '-'} `}</p>
            </div>
        ),
    },

    {
        field: 'managedBy',
        headerName: 'User',
        fieldType: 'render',
        renderCell: (params: ILogistioPartnersFees) => {
            const manager = params?.managedBy as IUser;

            return manager ? (
                <div className="flex gap-2 px-3 py-1 text-sm font-medium  bg-blue-200   rounded-full w-fit">
                    {manager?.profileImage ? (
                        <img src={manager?.profileImage} alt="profileImage" className="w-5 h-5 !rounded-full" />
                    ) : (
                        <img src={profileImg} alt="profileImage" className="w-5 h-5 !rounded-full" />
                    )}

                    <span className="whitespace-nowrap font-small text-blue-600 dark:text-white capitalize my-auto">
                        {`${manager.firstName || ''} ${manager.lastName || ''}`}
                    </span>
                </div>
            ) : (
                <p>-</p>
            );
        },
    },

    {
        field: 'Action',
        headerName: '',
        actionType: 'LogistioPartnerFeesActions',
        classField: 'w-[10px] min-w-[10px]',
        classHeader: 'w-[10px] min-w-[10px] ',
    },
];
