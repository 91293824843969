/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { Select, Tabs } from 'flowbite-react';
import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment';
import { adminDashboardType } from 'services/admin/dashboard';
import { ICallCenter } from 'pages/callCenterList/callCenter.types';
import { countries } from 'utils/countryList';
import { getAllCallCenters } from 'services/admin/callCenter';
import CustomSelect from 'components/customSelect/customSelect';
import { getCustomers } from 'services/admin/customer';
import SellerPerformance from './adminDashboard/sellers';
import CallCenterReport from './adminDashboard/callCenter';
import { IdateRange, IfilterDashboard } from './dashboard.types';
import LogistioPerformance from './adminDashboard/logistio';

export interface IDashboardCustomer {
    totalOrdersCount: number;
    confirmedCount: number;
    deliveredCount: number;
    sellerIncome: number;
    netProfit?: number;
    confirmationRate: number;
    deliveryRate: number;
    customerFirstName: string;
    customerLastName: string;
    _id?: string;
}
export interface IadminDashboard {
    customers?: IDashboardCustomer[];
    averageDeliveryRate?: number;
    averageConfirmationRate?: number;
    totalOrders?: number;
    totalConfirmed?: number;
    totalDelivered?: number;
}

interface ICustomer {
    totalNumber?: number;
    customersList?: [];
}

const Dashboard = () => {
    const [callCenters, setCallCenter] = useState<ICallCenter[]>([]);
    const [customers, setCustomers] = useState<ICustomer>({ totalNumber: 0, customersList: [] });
    const [activeTab, setActiveTab] = useState<number>(0);
    const types = Object.values(adminDashboardType);

    const startDate = moment
        .tz('Asia/Shanghai')
        .startOf('month')
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .format();
    const endDate = moment
        .tz('Asia/Shanghai')
        .endOf('month')
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .format();
    const [filter, setFilter] = useState<IfilterDashboard>({
        dateRange: { startDate, endDate },
        type: adminDashboardType.codWithCC,
    });
    const [dateRange, setRangeValue] = useState<IdateRange | null>({
        startDate: moment.tz(startDate, 'Asia/Shanghai').format('YYYY-MM-DD'),
        endDate: moment.tz(endDate, 'Asia/Shanghai').format('YYYY-MM-DD'),
    });

    useEffect(() => {
        getAllCallCenters({ limit: 1000 }).then((res) => {
            setCallCenter(res.data.docs);
        });
        getCustomers({ limit: 1000, status: 2 }).then((res) =>
            setCustomers({ totalNumber: res?.data?.docs?.length, customersList: res?.data?.docs }),
        );
    }, []);

    const handleRangeFilterChange = (newValue: any) => {
        let newRange: typeof newValue | undefined;
        if (newValue && newValue.startDate && newValue.endDate) {
            const startD = moment.tz(newValue.startDate, 'Asia/Shanghai');
            const endD = moment.tz(newValue.endDate, 'Asia/Shanghai');
            endD.set('hour', 23);
            endD.set('minute', 59);
            endD.set('second', 59);
            startD.set('hour', 0);
            startD.set('minute', 0);
            startD.set('second', 0);
            newRange = {
                startDate: startD.format(),
                endDate: endD.format(),
            };
            setRangeValue({
                startDate: startD.format('YYYY-MM-DD'),
                endDate: endD.format('YYYY-MM-DD'),
            });
        } else setRangeValue(newValue);
        setFilter((oldFilter) => ({ ...oldFilter, dateRange: newRange }));
    };

    const handleAddCountry = (e: any) => {
        if (e?.target.value !== '') {
            if (filter?.countries?.includes(e.target.value)) {
                setFilter({
                    ...filter,
                    countries:
                        e.target.value === 'select_all'
                            ? []
                            : filter?.countries?.filter((elt: any) => elt !== e.target.value && elt !== 'select_all'),
                });
            } else {
                setFilter({
                    ...filter,
                    countries:
                        e.target.value === 'select_all'
                            ? ['select_all', ...(countries as [])?.map((elt: any) => elt?.label)]
                            : [
                                  ...(filter?.countries?.filter((elt: string) => elt !== 'select_all') || []),
                                  e.target.value,
                              ],
                });
            }
        } else {
            setFilter({ ...filter, countries: [] });
        }
    };

    const handleAddCustomers = (e: any) => {
        if (e?.target.value !== '') {
            if (filter?.customers?.includes(e.target.value)) {
                setFilter({
                    ...filter,
                    customers:
                        e.target.value === 'select_all'
                            ? []
                            : filter?.customers?.filter(
                                  (elt: string) => elt !== e.target.value && elt !== 'select_all',
                              ),
                });
            } else {
                setFilter({
                    ...filter,
                    customers:
                        e.target.value === 'select_all'
                            ? ['select_all', ...(customers?.customersList as [])?.map((elt: any) => elt?._id)]
                            : [
                                  ...(filter?.customers?.filter((elt: string) => elt !== 'select_all') || []),
                                  e.target.value,
                              ],
                });
            }
        } else {
            setFilter({ ...filter, customers: [] });
        }
    };

    return (
        <>
            <div>
                <div className="m-4">
                    <h3>Dashboard</h3>
                </div>
                <div className="flex items-center w-full ">
                    <div className="flex justify-between  w-full  ">
                        <div className="flex items-center w-full p-1">
                            {[0, 1].includes(activeTab) ? (
                                <>
                                    <div className="p-1">
                                        <CustomSelect
                                            optionList={customers?.customersList}
                                            selectedOptions={filter?.customers}
                                            onChange={handleAddCustomers}
                                            placeholder="Select the seller (s)"
                                        />
                                    </div>
                                    <div id="select" className="p-1">
                                        <Select
                                            id="operationType"
                                            required
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    setFilter({ ...filter, country: e.target.value });
                                                } else {
                                                    setFilter({ ...filter, country: undefined });
                                                }
                                            }}
                                        >
                                            <option value="">All Countries</option>
                                            {countries.map((country: any) => {
                                                return (
                                                    <option key={country.label} value={country.label}>
                                                        {country.label}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                </>
                            ) : null}

                            {activeTab === 2 ? (
                                <>
                                    <div className="p-1">
                                        <Select
                                            id="callCenterFilter"
                                            required
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    setFilter({ ...filter, callCenter: e.target.value });
                                                } else {
                                                    setFilter({ ...filter, callCenter: undefined });
                                                }
                                            }}
                                        >
                                            <option value="">All Call Centers</option>
                                            {callCenters?.map((cc: any) => (
                                                <option key={cc._id} value={cc._id}>
                                                    {cc.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div id="select" className="p-1">
                                        <CustomSelect
                                            optionList={countries}
                                            selectedOptions={filter?.countries}
                                            onChange={handleAddCountry}
                                            placeholder="Select Countries"
                                        />
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                    <div className="flex items-center w-full justify-end ">
                        {[0, 1].includes(activeTab) ? (
                            <div id="select" className="p-1">
                                <Select
                                    id="operationType"
                                    required
                                    onChange={(e) => {
                                        if (e.target.value !== '') {
                                            setFilter({ ...filter, type: e.target.value });
                                        }
                                    }}
                                >
                                    {types.map((type: string) => {
                                        return (
                                            <option key={type} value={type}>
                                                {type}
                                            </option>
                                        );
                                    })}
                                </Select>
                            </div>
                        ) : null}
                        <div id="datePicker" className="block">
                            <Datepicker
                                inputId="orderDate"
                                value={dateRange}
                                containerClassName="relative z-100"
                                showShortcuts
                                useRange
                                toggleIcon={() => null}
                                inputClassName="text-center ml-1 bg-gray-50 border-gray-300 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 py-2.5 text-sm rounded-lg "
                                onChange={handleRangeFilterChange}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <Tabs
                        aria-label="Tabs with icons"
                        // eslint-disable-next-line react/style-prop-object
                        style="underline"
                        onActiveTabChange={(e: number) => setActiveTab(e)}
                        theme={{
                            tablist: {
                                tabitem: {
                                    base: 'flex items-center justify-center px-4 h-14 rounded-t-lg  text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                },
                            },
                        }}
                    >
                        <Tabs.Item
                            title={
                                <div className="flex items-center gap-2">
                                    <div>Logistio</div>
                                </div>
                            }
                        >
                            <LogistioPerformance filter={filter} />
                        </Tabs.Item>

                        <Tabs.Item
                            title={
                                <div className="flex items-center gap-2">
                                    <div>Seller</div>
                                </div>
                            }
                        >
                            <SellerPerformance filter={filter} activeSeller={customers?.totalNumber} />
                        </Tabs.Item>

                        <Tabs.Item
                            title={
                                <div className="flex items-center gap-2">
                                    <div>Call Center</div>
                                </div>
                            }
                        >
                            <CallCenterReport filter={filter} />
                        </Tabs.Item>
                    </Tabs>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
