/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { IStockShipment } from 'services/admin/stockShipment';
import { Box, MenuItem, Select } from '@mui/material';
import { Accordion, Button } from 'flowbite-react';
import { v4 as uuidv4 } from 'uuid';

import { MdOutlineDeleteForever } from 'react-icons/md';
import InputField from 'components/inputField/InputField';
import { CiCirclePlus } from 'react-icons/ci';
import { accordContent, accordTitle } from 'pages/configurationAdmin/configuration.styles';
import { ResellerStockShipment } from 'services/admin/resellerStock';
import { IBoxes, IBoxProduct, IErrors } from './pickUpDrawer';

export const Boxes: React.FC<{
    shipmentsData: IStockShipment[] | ResellerStockShipment[];
    boxes: IBoxes[];
    setBoxes: any;
    errors: IErrors[];
}> = ({ shipmentsData, boxes, setBoxes, errors }) => {
    const handleAddBox = () => {
        const newBox = {
            id: uuidv4(),
            weight: '',
            width: '',
            length: '',
            height: '',
            products: [
                {
                    id: uuidv4(),
                    name: '',
                    _id: '',
                    qty: 0,
                },
            ],
        };

        setBoxes([...boxes, newBox]);
    };

    const handleDeleteBox = (boxIndex: number) => {
        if (boxIndex > -1 && boxIndex < boxes?.length && boxes?.length >= 2) {
            setBoxes(boxes.filter((_, index) => index !== boxIndex));
        }
    };

    const handleUpdateBox = (boxIndex: number, field: string, value: number | string) => {
        setBoxes((prevBoxes: IBoxes[]) => {
            const updatedBoxes = [...prevBoxes];
            updatedBoxes[boxIndex] = {
                ...updatedBoxes[boxIndex],
                [field]: value,
            };
            return updatedBoxes;
        });
    };

    const handleUpdateProductBox = (
        indexBox: number,
        field: string,
        value: number | string | any,
        indexProduct: number,
    ) => {
        const updatedBoxes = [...boxes];
        const selectedBox = updatedBoxes[indexBox];
        const selectedProduct = selectedBox.products[indexProduct];

        if (field === 'id') {
            const selectedShipment = (shipmentsData as (IStockShipment | ResellerStockShipment)[])?.find((elt) => {
                const productId = (elt as IStockShipment).product?._id;
                const resellerProductId = (elt as ResellerStockShipment).resellerProduct?._id;
                return productId === value || resellerProductId === value;
            });
            selectedProduct.name =
                ((selectedShipment as IStockShipment)?.product?.itemKind === 'variant'
                    ? `${(selectedShipment as IStockShipment)?.parentProduct?.name} ${(
                          selectedShipment as IStockShipment
                      )?.product?.name}`
                    : (selectedShipment as IStockShipment)?.product?.name) ||
                (selectedShipment as ResellerStockShipment)?.resellerProduct?.name ||
                '';
            selectedProduct.shipmentId = selectedShipment?._id || '';
            selectedProduct._id = value as string;
        } else if (field === 'qty') {
            selectedProduct.qty = value as number;
        }

        setBoxes(updatedBoxes);
    };

    const handleDeleteProductFromBox = (indexBox: number, indexProduct: number) => {
        setBoxes((prevBoxes: IBoxes[]) => {
            const newBoxes = [...prevBoxes];
            newBoxes[indexBox].products.splice(indexProduct, 1);
            return newBoxes;
        });
    };

    const handleAddProductToBox = (indexBox: number) => {
        const updatedBoxes = [...boxes];
        const selectedBox = updatedBoxes[indexBox];
        selectedBox.products.push({
            id: uuidv4(),
            name: '',
            _id: '',
            qty: 0,
            shipmentId: '',
        });
        setBoxes(updatedBoxes);
    };

    return (
        <div className="w-full h-full flex flex-col overflow-hidden px-4">
            <div>
                <Accordion className="min-h-36 max-h-72 overflow-y-auto " collapseAll alwaysOpen flush>
                    <Accordion.Panel className="!ring-0 focus:!ring-0 focus:!ring-transparent">
                        <Accordion.Title className="overflow-hidden -px-4" theme={accordTitle}>
                            Selected products ({shipmentsData?.length})
                        </Accordion.Title>
                        <Accordion.Content theme={accordContent}>
                            <div className="flex flex-col w-full">
                                <div>
                                    <table className="w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400  ">
                                        <thead className="text-xs rounded-lg  bg-gray-200 dark:bg-gray-700  font-normal text-gray-600  dark:text-gray-400 ">
                                            <tr>
                                                <th scope="col" className="p-2">
                                                    Product
                                                </th>
                                                <th scope="col" className="py-2">
                                                    Quantity
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {shipmentsData?.map((shipment) => (
                                                <tr
                                                    className=" border-b   dark:border-gray-700 font-bold  bg-white dark:bg-gray-800 "
                                                    key={uuidv4()}
                                                >
                                                    <td className="p-2">
                                                        {(shipment as IStockShipment)?.product?.itemKind === 'variant'
                                                            ? `${(shipment as IStockShipment)?.parentProduct?.name} ${(
                                                                  shipment as IStockShipment
                                                              )?.product?.name}`
                                                            : (shipment as IStockShipment)?.product?.name ||
                                                              (shipment as ResellerStockShipment)?.resellerProduct
                                                                  ?.name}
                                                    </td>

                                                    <td className=" py-2 ">{shipment?.quantity}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Accordion.Content>
                    </Accordion.Panel>
                </Accordion>
            </div>

            <div className="pt-4  flex  w-full items-center justify-between">
                <p className="font-bold text-gray-600 dark:text-gray-300">Boxes</p>
            </div>

            <div className="h-auto overflow-y-auto">
                {boxes?.map((box, index) => (
                    <Box key={box.id} sx={{ margin: '5px 0px', padding: '5px 0px' }}>
                        <Box
                            sx={{
                                padding: '10px 0px',
                                border: '1px solid',
                                borderRadius: '8px',
                                borderColor: 'borderColor',
                            }}
                        >
                            <div key={box.id} className="p-1 flex w-full items-center justify-between">
                                <div>
                                    <p className="font-bold text-gray-600 dark:text-gray-300 px-2">Box {index + 1}</p>
                                </div>
                                <div className="flex items-center gap-2">
                                    {boxes?.length >= 2 && index !== 0 && (
                                        <Button size="s" color="light" onClick={() => handleDeleteBox(index)}>
                                            <MdOutlineDeleteForever color="red" className="w-5 h-5" />
                                        </Button>
                                    )}
                                </div>
                            </div>

                            <div key={box.id}>
                                <div className="my-1  mx-4">
                                    <table className="w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400  ">
                                        <thead className="text-xs rounded-lg  font-normal text-gray-700  dark:text-gray-400 ">
                                            <tr>
                                                <th scope="col" className="py-1">
                                                    Weight (Kg)
                                                </th>
                                                <th scope="col" className="py-1">
                                                    Width (Cm)
                                                </th>
                                                <th scope="col" className="py-1">
                                                    Length (Cm)
                                                </th>
                                                <th scope="col" className="py-1">
                                                    Height (Cm)
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody key={box.id}>
                                            <tr className=" bg-white dark:bg-gray-800 " key={box.id}>
                                                <td key={box.id} className="py-1">
                                                    {errors?.some(
                                                        (error: IErrors) =>
                                                            error.box === index && error.element === 'weight',
                                                    ) && (
                                                        <div
                                                            className="bg-red-100 text-red-800 border border-red-300 rounded p-1 "
                                                            style={{ width: '180px' }}
                                                        >
                                                            {
                                                                errors?.find(
                                                                    (error: IErrors) =>
                                                                        error.box === index &&
                                                                        error.element === 'weight',
                                                                )?.message
                                                            }
                                                        </div>
                                                    )}
                                                    <InputField
                                                        style={{
                                                            width: '100px',
                                                        }}
                                                        key={`${box.id}-weight`}
                                                        value={box?.weight}
                                                        name={`${box.id}-weight`}
                                                        placeholder="Weight"
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            handleUpdateBox(index, 'weight', e.target.value);
                                                        }}
                                                    />
                                                </td>

                                                <td className=" py-1 ">
                                                    {errors?.some(
                                                        (error: IErrors) =>
                                                            error.box === index && error.element === 'width',
                                                    ) && (
                                                        <div
                                                            className="bg-red-100 text-red-800 border border-red-300 rounded p-1 "
                                                            style={{ width: '180px' }}
                                                        >
                                                            {
                                                                errors?.find(
                                                                    (error: IErrors) =>
                                                                        error.box === index &&
                                                                        error.element === 'width',
                                                                )?.message
                                                            }
                                                        </div>
                                                    )}
                                                    <InputField
                                                        style={{ width: '100px' }}
                                                        key={`${box.id}-width`}
                                                        value={box?.width}
                                                        name={`${box.id}-width`}
                                                        placeholder="width"
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            handleUpdateBox(index, 'width', e.target.value);
                                                        }}
                                                    />
                                                </td>

                                                <td className=" py-1">
                                                    {errors?.some(
                                                        (error: IErrors) =>
                                                            error.box === index && error.element === 'length',
                                                    ) && (
                                                        <div
                                                            className="bg-red-100 text-red-800 border border-red-300 rounded p-1 "
                                                            style={{ width: '180px' }}
                                                        >
                                                            {
                                                                errors?.find(
                                                                    (error: IErrors) =>
                                                                        error.box === index &&
                                                                        error.element === 'length',
                                                                )?.message
                                                            }
                                                        </div>
                                                    )}
                                                    <InputField
                                                        style={{ width: '100px' }}
                                                        key={`${box.id}-length`}
                                                        value={box?.length}
                                                        name={`${box.id}-length`}
                                                        placeholder="length"
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            handleUpdateBox(index, 'length', e.target.value);
                                                        }}
                                                    />
                                                </td>

                                                <td className=" py-1">
                                                    {errors?.some(
                                                        (error: IErrors) =>
                                                            error.box === index && error.element === 'height',
                                                    ) && (
                                                        <div
                                                            className="bg-red-100 text-red-800 border border-red-300 rounded p-1 "
                                                            style={{ width: '180px' }}
                                                        >
                                                            {
                                                                errors?.find(
                                                                    (error: IErrors) =>
                                                                        error.box === index &&
                                                                        error.element === 'height',
                                                                )?.message
                                                            }
                                                        </div>
                                                    )}
                                                    <InputField
                                                        style={{ width: '100px' }}
                                                        key={`${box.id}-height`}
                                                        value={box?.height}
                                                        name={`${box.id}-height`}
                                                        placeholder="height"
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            handleUpdateBox(index, 'height', e.target.value);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="my-4 relative  transition duration-9000 ease-in-out">
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 relative">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                                                <tr>
                                                    <th scope="col" className="px-6 py-2">
                                                        Product
                                                    </th>
                                                    <th scope="col" className="px-6 py-2">
                                                        Qty
                                                    </th>

                                                    <th scope="col" className="px-6 py-2" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {box?.products?.map((product: IBoxProduct, indexProduct) => (
                                                    <tr
                                                        key={product.id}
                                                        className="border-b bg-white dark:bg-gray-800 dark:border-gray-700"
                                                    >
                                                        <th
                                                            scope="row"
                                                            className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                        >
                                                            <Select
                                                                sx={{
                                                                    margin: '10px 0px',
                                                                    borderRadius: '8px',
                                                                    height: '40px',
                                                                    width: '350px',
                                                                    backgroundColor: 'inputBackground',
                                                                }}
                                                                id="products"
                                                                name="products"
                                                                value={product._id}
                                                                onChange={(e) => {
                                                                    handleUpdateProductBox(
                                                                        index,
                                                                        'id',
                                                                        e.target.value,
                                                                        indexProduct,
                                                                    );
                                                                }}
                                                            >
                                                                {shipmentsData?.map((ship) => (
                                                                    <MenuItem
                                                                        disabled={box?.products
                                                                            ?.map((elt) => elt._id)
                                                                            .includes(
                                                                                (ship as IStockShipment)?.product
                                                                                    ?._id ||
                                                                                    (ship as ResellerStockShipment)
                                                                                        ?.resellerProduct?._id,
                                                                            )}
                                                                        key={
                                                                            (ship as IStockShipment)?.product?._id ||
                                                                            (ship as ResellerStockShipment)
                                                                                ?.resellerProduct?._id
                                                                        }
                                                                        value={
                                                                            (ship as IStockShipment)?.product?._id ||
                                                                            (ship as ResellerStockShipment)
                                                                                ?.resellerProduct?._id
                                                                        }
                                                                    >
                                                                        {(ship as IStockShipment)?.product?.itemKind ===
                                                                        'variant'
                                                                            ? `${(ship as IStockShipment)?.parentProduct
                                                                                  ?.name} ${(ship as IStockShipment)
                                                                                  ?.product?.name}`
                                                                            : (ship as IStockShipment)?.product?.name ||
                                                                              (ship as ResellerStockShipment)
                                                                                  ?.resellerProduct?.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </th>
                                                        <td className="px-6 py-2">
                                                            <InputField
                                                                style={{ width: '100px' }}
                                                                name="qty"
                                                                placeholder="qty"
                                                                value={box.products[indexProduct].qty}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    if (!isNaN(Number(e.target.value))) {
                                                                        handleUpdateProductBox(
                                                                            index,
                                                                            'qty',
                                                                            Number(e.target.value),
                                                                            indexProduct,
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </td>

                                                        <td className="px-6 py-2">
                                                            {box?.products?.length >= 2 && (
                                                                <Button
                                                                    size="s"
                                                                    color="light"
                                                                    onClick={() =>
                                                                        handleDeleteProductFromBox(index, indexProduct)
                                                                    }
                                                                >
                                                                    <MdOutlineDeleteForever
                                                                        color="red"
                                                                        className="w-5 h-5"
                                                                    />
                                                                </Button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {box.products?.length !== shipmentsData?.length && (
                                            <div className="px-6 mt-2">
                                                <Button
                                                    onClick={() => handleAddProductToBox(index)}
                                                    size="s"
                                                    color="light"
                                                >
                                                    <CiCirclePlus className="w-5 h-5" />
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Box>
                ))}

                <div className="mt-2 mb-4 pr-4 flex w-full items-end justify-end">
                    <Button color="light" onClick={handleAddBox}>
                        Add Box
                    </Button>
                </div>
            </div>
        </div>
    );
};
