import { IUser } from 'services/auth';
import { getBearerToken } from 'utils/helpers/auth';
import axios from 'utils/axios';

export interface IPricingLogistioPartnersFees {
    _id?: string;
    country: string;
    shippedAndDeliveredPrice: number;
    shippedAndReturnedPrice: number;
    percentFromSellPrice: number;
    handlingPerUnit: number;
    upDownCross: number;
    newLeadPrice: number;
    confirmedLeadPrice: number;
    deliveredLeadPrice: number;
}
export interface ILogistioPartnersFees {
    _id: string;
    pricing: IPricingLogistioPartnersFees[];
    updatedAt?: string;
    createdAt?: string;
    managedBy?: IUser | string;
    feeType: string;
}

export const getLogistioPartnerFeesList = async () => {
    const config = getBearerToken();
    const response = await axios.get<ILogistioPartnersFees[]>(`/admin/logistioPartnerFees`, config);
    return response;
};

export const updateLogistioPartnerFees = async (id: string, data: any) => {
    const config = getBearerToken();
    const response = await axios.put(`/admin/logistioPartnerFees/${id}`, data, config);
    return response;
};
