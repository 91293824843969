/* eslint-disable no-underscore-dangle */
import DataTable from 'components/tables/tables';
import { TableColDef } from 'components/tables/tables.types';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';

import { IoEye } from 'react-icons/io5';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { Button, Tabs, TabsRef } from 'flowbite-react';
import {
    getLogistioBulkFeesList,
    ICodNoCCPricing,
    ICodPricingItem,
    ILogistioBulkFees,
    IPrepaidNoCCPricing,
} from 'services/admin/logistioBulkFees';
import { getLogistioPartnerFeesList, ILogistioPartnersFees } from 'services/admin/logistioPartnersFees';

import PricingConfiguration from '../pricingConfiguration/PricingConfiguaration';
import { logistioBulkPartnerColumn } from './partnerFees/partnerFeesElements';
import LogistioBulkFeesModal from './sellerFees/logistioBulkFeesModal';
import CustomersList from './sellerFees/customersList';

export interface ILogisioBulkModals {
    modal: boolean;
    userDetails: boolean;
    feeDetails: boolean;
}

export interface IPricingConfigurationBulk {
    codWithCC: Array<ICodPricingItem>;
    codNoCC: Array<ICodNoCCPricing>;
    prepaidNoCC: Array<IPrepaidNoCCPricing>;
}

interface IConfigPricing {
    pricing: IPricingConfigurationBulk;
}
interface Ifilter {
    feeType: 'seller' | 'partner';
}
type ActionType = 'ADD' | 'UPDATE' | 'DELETE' | 'ASSIGN' | 'ASSIGN_UPDATE' | '';

const LogistioBulkFees = () => {
    const [open, setOpen] = useState<ILogisioBulkModals>({
        modal: false,
        userDetails: false,
        feeDetails: false,
    });
    const [logistioBulkFees, setLogistioBulkFees] = useState<ILogistioBulkFees[] | ILogistioPartnersFees[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<Ifilter>({
        feeType: 'seller',
    });
    const tabsRef = useRef<TabsRef | null>(null);
    const [action, setAction] = useState<ActionType>('');
    const [selectedFee, setSelectedFee] = useState<ILogistioBulkFees | ILogistioPartnersFees | any>({});
    const [configPricing, setConfigPricing] = useState<IConfigPricing['pricing']>({
        codNoCC: [],
        codWithCC: [],
        prepaidNoCC: [],
    });

    const handleDuplicateFee = (fee: ILogistioBulkFees) => {
        setSelectedFee(fee);
        setConfigPricing(fee.pricing);
        setAction('ADD');
        setOpen({ modal: false, userDetails: false, feeDetails: true });
    };
    const handleFeeDetails = (fee: ILogistioBulkFees) => {
        setSelectedFee(fee);
        setConfigPricing(fee.pricing);
        setAction('UPDATE');
        setOpen({ modal: false, userDetails: false, feeDetails: true });
    };

    const handleAssignCustumers = (fee: ILogistioBulkFees) => {
        setSelectedFee(fee);
        setAction('ASSIGN');
        setOpen({ modal: true, userDetails: false, feeDetails: false });
    };

    const refetchGetFees = () => {
        getLogistioBulkFeesList().then((res) => {
            setLogistioBulkFees(res?.data || []);
            if (action === 'ADD') {
                setOpen({ modal: true, userDetails: false, feeDetails: false });
                setAction('ASSIGN_UPDATE');
            }
        });
        setAction(action === 'ASSIGN_UPDATE' ? 'UPDATE' : action);
    };

    const handleDeleteFee = (fee: ILogistioBulkFees) => {
        setSelectedFee(fee);
        setAction('DELETE');
        setOpen({ modal: true, userDetails: false, feeDetails: false });
    };

    const handleGetListOfCustomers = (fee: ILogistioBulkFees) => {
        setSelectedFee(fee);
        setOpen({ modal: false, userDetails: true, feeDetails: false });
    };

    useEffect(() => {
        if (filter.feeType) {
            setIsLoading(true);
            if (filter.feeType === 'seller') {
                getLogistioBulkFeesList().then((res) => {
                    setLogistioBulkFees(res?.data || []);
                    setIsLoading(false);
                });
            } else if (filter.feeType === 'partner') {
                getLogistioPartnerFeesList().then((res) => {
                    setLogistioBulkFees(res?.data || []);
                    setIsLoading(false);
                });
            } else {
                setIsLoading(false);
            }
        }
    }, [filter.feeType]);

    const logistioBulkFeesColumn: TableColDef<ILogistioBulkFees>[] = [
        {
            field: 'name',
            headerName: 'Name',
            fieldType: 'render',
            classHeader: 'text-center',
            renderCell: (params: ILogistioBulkFees) => (
                <div className="flex items-center gap-4">
                    <p>{`${params?.name || ''} `}</p>
                    {params.isDefault && (
                        <Button size="s" color="blue" disabled>
                            <p className="text-xs">Default</p>
                        </Button>
                    )}
                </div>
            ),
        },

        {
            field: 'updatedAt',
            headerName: 'Updated At',
            fieldType: 'render',
            classHeader: 'text-center',
            renderCell: (params: ILogistioBulkFees) => (
                <p>{`${params?.updatedAt ? format(new Date(params?.updatedAt), 'dd/MM/yyyy') : '-'} `}</p>
            ),
        },

        {
            field: 'customersCount',
            headerName: 'Sellers',
            fieldType: 'render',
            renderCell: (params: ILogistioBulkFees) => {
                return (
                    <div className="flex items-center gap-2">
                        <Button
                            title={
                                params.customersCount
                                    ? 'List of sellers'
                                    : 'This offer has not yet been assigned to a seller.'
                            }
                            disabled={!params?.customersCount}
                            onClick={() => handleGetListOfCustomers(params)}
                            color="light"
                        >
                            <p>{`${params?.customersCount || 0}`}</p>
                        </Button>
                    </div>
                );
            },
        },

        {
            field: 'Action',
            headerName: 'Actions',
            actionType: 'LogistioBulkFeesActions',
            fieldType: 'render',
            classField: 'w-[10px] min-w-[10px]',
            classHeader: 'w-[10px] min-w-[10px] ',
            renderCell: (params: ILogistioBulkFees) => (
                <div className="flex gap-0.5 pr-2">
                    <Button title="Assign new sellers" onClick={() => handleAssignCustumers(params)} color="white">
                        <FaPlus className="w-3 h-3" />
                    </Button>
                    {params.isDefault && (
                        <div>
                            <Button
                                title="Create new fee from this version"
                                color="white"
                                onClick={() => handleDuplicateFee(params)}
                            >
                                <HiOutlineDuplicate className="w-5 h-5 " />
                            </Button>
                        </div>
                    )}
                    {!params.isDefault && (
                        <div>
                            <Button title="Delete fee" color="white" onClick={() => handleDeleteFee(params)}>
                                <FaTrash className="w-4 h-4 text-red-500" />
                            </Button>
                        </div>
                    )}
                    <div>
                        <Button title="Display fee details" color="white" onClick={() => handleFeeDetails(params)}>
                            <IoEye className="w-5 h-5 " />
                        </Button>
                    </div>
                </div>
            ),
        },
    ];

    const getTabValue = (tab: number) => {
        switch (tab) {
            case 0:
                return 'seller';
            case 1:
                return 'partner';

            default:
                return 'seller';
        }
    };

    return (
        <>
            <Tabs
                aria-label="Tabs with icons"
                // eslint-disable-next-line react/style-prop-object
                theme={{
                    tablist: {
                        tabitem: {
                            base: 'flex items-center text-blue-500 justify-center px-4 h-14 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                            styles: {
                                default: {
                                    base: 'flex items-center text-blue-500 justify-center px-4 h-14  text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
                                    active: {
                                        on: 'text-main border-main border-b-2',
                                        off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300',
                                    },
                                },
                            },
                        },
                    },
                }}
                ref={tabsRef}
                onActiveTabChange={(tab) => {
                    if (getTabValue(tab) !== filter.feeType) {
                        setFilter({ ...filter, ...{ feeType: getTabValue(tab) } });
                    }
                }}
            >
                {[
                    { value: 'seller', label: 'Seller fees' },
                    { value: 'partner', label: 'Logistio fees' },
                ].map((item: any) => {
                    return (
                        <Tabs.Item
                            title={<div className="h-full py-4">{item.label}</div>}
                            key={item.value}
                            style={{ height: '64px !important', padding: '0px 24px' }}
                            className="!py-0 xs:!h-14"
                        >
                            <DataTable
                                rows={logistioBulkFees}
                                setRows={setLogistioBulkFees}
                                columns={item?.value === 'seller' ? logistioBulkFeesColumn : logistioBulkPartnerColumn}
                                pageState={{
                                    isLoading,
                                    total: 0,
                                    pageSize: 0,
                                    page: 0,
                                    count: 0,
                                }}
                                setPageState={() => {}}
                            />
                        </Tabs.Item>
                    );
                })}
            </Tabs>

            {open.modal && (
                <LogistioBulkFeesModal
                    key="modal"
                    fee={selectedFee}
                    action={action}
                    setAction={setAction}
                    open={open}
                    setOpen={setOpen}
                    refetchGetFees={refetchGetFees}
                />
            )}
            {open.userDetails && (
                <CustomersList
                    handleAssignCustumers={handleAssignCustumers}
                    key="customersList"
                    fee={selectedFee}
                    openDetails={open.userDetails}
                    setOpenDetails={setOpen}
                />
            )}

            {open.feeDetails && (
                <PricingConfiguration
                    key={selectedFee._id}
                    open={open.feeDetails}
                    fee={selectedFee}
                    setOpen={setOpen}
                    pricing={configPricing}
                    configId={selectedFee._id}
                    refetchGetFees={refetchGetFees}
                    action={action}
                    setConfigId={setSelectedFee}
                    setAction={setAction}
                />
            )}
        </>
    );
};

export default LogistioBulkFees;
